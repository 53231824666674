import Grid from '@mui/material/Grid';
import { ConnectedBankAccounts } from './ConnectedBankAccounts';
import { ReconcileESignature } from './ReconcileESignature';
import { ReinitiateMercury } from './ReinitiateMercury';

export const AdvancedActionTab = ({ data }: { data: any }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <ReinitiateMercury companyId={data.id} />
      </Grid>
      <Grid item xs={8}>
        <ReconcileESignature companyId={data.id} />
      </Grid>
      <Grid item xs={8}>
        <ConnectedBankAccounts companyId={data.id} />
      </Grid>
    </Grid>
  );
};
