import BookmarkIcon from '@mui/icons-material/Bookmark';
import BusinessIcon from '@mui/icons-material/Business';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import {
  Badge,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import SavedMessagesListModal from '../../components/SavedMessagesListModal';
import SavedMessagesModal from '../../components/SavedMessagesModal';
import { hasPermission } from '../../lib/SGHelper';
import { ChatActor } from './ChatActor';
import { ChatMaster } from './ChatMaster';
import { ChatMessage } from './ChatMessage';
import { ChatSavedMessage } from './ChatSavedMessage';
import { OpenChat } from './OpenChat';

export const Chat = () => {
  // States for selections and data
  const [selectedActor, setSelectedActor] = useState<ChatActor | null>(null);
  const [openChats, setOpenChats] = useState<OpenChat[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
  const [chatHistory, setChatHistory] = useState<ChatMaster[]>([]);
  const [replyMessage, setReplyMessage] = useState('');
  const [loadingOpenChats, setLoadingOpenChats] = useState(false);
  const [loadingChatHistory, setLoadingChatHistory] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState<number[]>([]);
  const [transferToActor, setTransferToActor] = useState<ChatActor | ''>('');
  const [isTransferring, setIsTransferring] = useState(false);
  const [savedMessages, setSavedMessages] = useState<ChatSavedMessage[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [savedListOpen, setSavedListOpen] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const { permissions } = usePermissions();

  const isCPA =
    hasPermission('ROLE_CPA_0128', permissions) ||
    hasPermission('ROLE_CPA_0988', permissions) ||
    hasPermission('ROLE_CPA_1', permissions) ||
    hasPermission('ROLE_CPA_2', permissions) ||
    hasPermission('ROLE_CPA_3', permissions);

  const isBookkeeper = hasPermission('ROLE_BOOK_KEEPER', permissions);

  const isCPA1 =
    hasPermission('ROLE_CPA_0128', permissions) || hasPermission('ROLE_CPA_1', permissions);

  // Add filteredActors variable to show specific actors based on permissions
  const filteredActors = isCPA
    ? [ChatActor.TAX_EXPERT]
    : isBookkeeper
    ? [ChatActor.BOOKKEEPER]
    : Object.values(ChatActor);

  // Auto-select first actor on mount
  useEffect(() => {
    const actors = Object.values(ChatActor);
    if (actors.length > 0 && !selectedActor) {
      handleActorSelect(actors[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Chat Actor selection and fetch OpenChat list
  const handleActorSelect = async (actor: ChatActor) => {
    setSelectedActor(actor);
    setSelectedCompany(null);
    setChatHistory([]);
    setLoadingOpenChats(true);
    dataProvider
      .get(`chat_master/open`)
      .then((data: any) => {
        console.log('open chats', data.data);
        setOpenChats(data.data);
      })
      .catch((error: any) => {
        console.log('error', error);
      })
      .finally(() => setLoadingOpenChats(false));

    dataProvider
      .get(`chat_master/saved-messages/${selectedActor}`)
      .then((data: any) => {
        console.log('saved messages', data.data);
        setSavedMessages(data.data);
      })
      .catch((error: any) => {
        console.log('error', error);
      })
      .finally(() => setLoadingChatHistory(false));
  };

  const filteredChats = openChats
    .filter((chat) => chat.owner === selectedActor)
    .filter((chat) => {
      if (selectedActor === ChatActor.TAX_EXPERT && isCPA) {
        if (isCPA1) {
          return chat.cpaId === 1;
        }
        return chat.cpaId === 2 || chat.cpaId === 3;
      }
      return true;
    });

  // Compute distinct companies using companyId as key
  const distinctCompanies = Array.from(
    new Map(
      filteredChats.map((chat) => [
        chat.companyId,
        { companyId: chat.companyId, companyName: chat.company },
      ])
    ).values()
  );

  // Auto-select first company if available
  useEffect(() => {
    if (!selectedCompany && distinctCompanies.length > 0) {
      handleCompanySelect(distinctCompanies[0].companyId);
    }
  }, [distinctCompanies, selectedCompany]);

  // Add helper to get tab value based on selectedActor
  const getLinkPath = (companyId: number, selectedActor: ChatActor | null): string => {
    let tab = 'bookkeeping';
    if (selectedActor === ChatActor.TAX_EXPERT) tab = 'federal-tax';
    else if (selectedActor === ChatActor.BOOKKEEPER) tab = 'bookkeeping';
    else if (selectedActor === ChatActor.ACCOUNT_MANAGER) tab = '';
    else if (selectedActor === ChatActor.SUPPORT) tab = '';
    return `company-master-data/${companyId}${tab ? `?tab=${tab}` : ''}`;
  };

  // Handle Company selection and fetch chat history
  const handleCompanySelect = async (companyId: number) => {
    setSelectedCompany(companyId);
    setLoadingChatHistory(true);
    dataProvider
      .get(`chat_master/${companyId}/owner/${selectedActor}`)
      .then((data: any) => {
        console.log('open chats', data.data);
        setChatHistory(data.data);
      })
      .catch((error: any) => {
        console.log('error', error);
      })
      .finally(() => setLoadingChatHistory(false));
  };

  // Handle sending reply
  const handleSendReply = async () => {
    if (!replyMessage.trim() || !selectedCompany) return;

    const data = {
      message: replyMessage,
      companyId: selectedCompany,
      owner: selectedActor,
      sendFrom: selectedActor,
    };

    dataProvider
      .post(`chat_master/reply/${selectedCompany}`, { data })
      .then((data: any, error: any) => {
        if (error) {
          throw new Error(error);
        }
        // Optimistically update chat history
        setChatHistory((prevHistory) => [...prevHistory, data.data]);
        setReplyMessage('');
        // setReload(!reload);
      })
      .catch((error: any) => {
        notify(error.message || error.error || error.code || error, { type: 'error' });
      })
      .finally(() => {});
  };

  // Replace openChatCount to count distinct companies for the actor
  const openChatCount = (actor: ChatActor) => {
    const chatsForActor = openChats
      .filter((chat) => chat.owner === actor)
      .filter((chat) => {
        if (actor === ChatActor.TAX_EXPERT && isCPA) {
          if (isCPA1) {
            return chat.cpaId === 1;
          }
          return chat.cpaId === 2 || chat.cpaId === 3;
        }
        return true;
      });
    const distinctCompanies = new Set(chatsForActor.map((chat) => chat.companyId));
    return distinctCompanies.size;
  };

  // Toggle message selection
  const handleMessageSelect = (id: number) => {
    setSelectedMessages((prev) =>
      prev.includes(id) ? prev.filter((msgId) => msgId !== id) : [...prev, id]
    );
  };

  // Handle transferring selected messages
  const handleTransfer = async () => {
    if (selectedMessages.length === 0 || !transferToActor || !selectedCompany || !selectedActor) {
      notify('Please select messages and a target actor', { type: 'warning' });
      return;
    }
    setIsTransferring(true);

    const data = { chatIds: selectedMessages, newOwner: transferToActor };

    dataProvider
      .post(`chat_master/transfer/${selectedActor}`, { data })
      .then(() => {
        setChatHistory((prev) => prev.filter((chat) => !selectedMessages.includes(chat.id)));
        setSelectedMessages([]);
        setTransferToActor('');
        notify('Transfer successful', { type: 'success' });
      })
      .catch((err: any) => notify(err.message || err, { type: 'error' }))
      .finally(() => setIsTransferring(false));
  };

  // Filter out the current actor from transfer options, excluding CLIENT as well
  const transferActorOptions = Object.values(ChatActor).filter(
    (actor) => actor !== selectedActor && actor !== ChatActor.CLIENT
  );

  // Set default transfer option to SUPPORT if available
  useEffect(() => {
    if (!transferToActor && transferActorOptions.includes(ChatActor.SUPPORT)) {
      setTransferToActor(ChatActor.SUPPORT);
    }
  }, [transferActorOptions, transferToActor]);

  // Filter out messages with type 'CHAT' and actions with type 'ACTION'
  const chatMessages = chatHistory.filter((chat) => chat.type === 'CHAT');
  const actions = chatHistory.filter((chat) => chat.type === 'ACTION');

  // Scroll chat container to bottom when chatMessages change
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]); // or [chatMessages] if you have that variable

  // Function to open modal for current actor
  const handleOpenSavedMessages = () => {
    setModalOpen(true);
  };

  // Function to open the list modal for current actor
  const handleOpenSavedMessagesList = () => {
    setSavedListOpen(true);
  };

  // Append selected saved message text to replyMessage
  const handleSelectSavedMessage = (msg: string) => {
    setReplyMessage((prev) => prev + msg);
    setSavedListOpen(false);
  };

  // Retrieve saved messages for current actor, defaulting to empty array
  const currentSavedMessages = savedMessages || [];

  return (
    <Box sx={{ flexGrow: 1, height: '100vh', bgcolor: 'background.default' }}>
      <Grid container sx={{ height: '100%' }}>
        {/* First Column - Chat Actors (small width) */}
        <Grid
          item
          xs={2}
          sx={{ borderRight: '1px solid #ccc', overflowY: 'auto', bgcolor: 'grey.100' }}
        >
          <List>
            {filteredActors.map((actor) => (
              <ListItemButton
                key={actor}
                onClick={() => handleActorSelect(actor)}
                selected={selectedActor === actor}
              >
                <PersonIcon />
                <Badge color="primary" badgeContent={openChatCount(actor)} sx={{ ml: 1 }}>
                  <ListItemText primary={actor} primaryTypographyProps={{ noWrap: true }} />
                </Badge>
              </ListItemButton>
            ))}
          </List>
        </Grid>

        {/* Second Column - Company Names (medium width) */}
        <Grid
          item
          xs={3}
          sx={{ borderRight: '1px solid #ccc', overflowY: 'auto', bgcolor: 'grey.50' }}
        >
          {loadingOpenChats ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
          ) : distinctCompanies.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 2,
                p: 2,
              }}
            >
              <SentimentDissatisfiedIcon sx={{ fontSize: 40, color: 'grey.500' }} />
              <Typography variant="subtitle1" color="textSecondary">
                No companies available
              </Typography>
            </Box>
          ) : (
            <List>
              {distinctCompanies.map((company) => (
                <ListItemButton
                  key={company.companyId}
                  onClick={() => handleCompanySelect(company.companyId)}
                  selected={selectedCompany === company.companyId}
                  sx={{
                    position: 'relative',
                    '&:hover .link-icon': { opacity: 1 },
                  }}
                >
                  <BusinessIcon sx={{ mr: 1 }} />
                  <ListItemText primary={company.companyName} />
                  <Box
                    className="link-icon"
                    sx={{
                      opacity: 0,
                      transition: 'opacity 0.3s ease',
                      position: 'absolute',
                      right: 8,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color:
                        selectedCompany === company.companyId ? 'text.primary' : 'primary.main',
                    }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent ListItemButton onClick
                      window.open(`#/${getLinkPath(company.companyId, selectedActor)}`, '_blank');
                    }}
                  >
                    <OpenInNewIcon />
                  </Box>
                </ListItemButton>
              ))}
            </List>
          )}
        </Grid>

        {/* Third Column - Chat History & Reply (remaining space) */}
        <Grid item xs={7} sx={{ display: 'flex', flexDirection: 'column', height: '90%' }}>
          {/* Sticky chat header with company link */}
          {selectedCompany && (
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 10,
                background: 'background.paper',
                borderBottom: '1px solid #ccc',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                px: 2,
                py: 1,
              }}
            >
              <Typography variant="h6" sx={{ fontSize: '16px' }}>
                Open Company Details
              </Typography>
              <Box
                sx={{ cursor: 'pointer', color: 'primary.main' }}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`#/${getLinkPath(selectedCompany, selectedActor)}`, '_blank');
                }}
              >
                <OpenInNewIcon />
              </Box>
            </Box>
          )}

          {/* Transfer Options: visible if one or more messages selected */}
          {selectedMessages.length > 0 && (
            <Card
              sx={{
                p: 2,
                m: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ mr: 2 }}>
                  {selectedMessages.length} message(s) selected
                </Typography>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setSelectedMessages([])}
                  sx={{ mr: 2 }}
                >
                  Clear Selection
                </Button>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControl size="small" sx={{ minWidth: 150, mr: 2 }}>
                  <InputLabel id="transfer-actor-label">Transfer to</InputLabel>
                  <Select
                    labelId="transfer-actor-label"
                    value={transferToActor}
                    label="Transfer to"
                    onChange={(e) => setTransferToActor(e.target.value as ChatActor)}
                  >
                    {transferActorOptions.map((actor) => (
                      <MenuItem key={actor} value={actor}>
                        {actor}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<TransferWithinAStationIcon />}
                  onClick={handleTransfer}
                  disabled={!transferToActor || isTransferring}
                >
                  {isTransferring ? 'Transferring...' : 'Transfer'}
                </Button>
              </Box>
            </Card>
          )}

          <Box
            ref={chatContainerRef}
            sx={{ flexGrow: 1, overflowY: 'auto', p: 2, bgcolor: 'background.paper' }}
          >
            {loadingChatHistory ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : chatMessages.length === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 2,
                  p: 2,
                }}
              >
                <SentimentDissatisfiedIcon sx={{ fontSize: 40, color: 'grey.500' }} />
                <Typography variant="subtitle1" color="textSecondary">
                  No messages available
                </Typography>
              </Box>
            ) : (
              chatMessages.map((chat, index) => {
                // If any action's remarks (comma-separated ids) contains this chat id, select its message
                const actionMsg = actions.find((act) =>
                  act.remarks
                    ?.split(',')
                    .map((r) => r.trim())
                    .includes(String(chat.id))
                )?.message;

                return (
                  <ChatMessage
                    key={chat.id}
                    id={chat.id}
                    onToggleMessage={(id) => console.log('Toggle message', id)}
                    marked={false} // adjust if needed
                    sendFrom={chat.sendFrom}
                    type={chat.type}
                    message={chat.message}
                    createdDate={chat.createdDate}
                    last={index === chatMessages.length - 1}
                    actionMessage={actionMsg}
                    // Only allow selection for chats from CLIENT
                    isSelectable={chat.sendFrom === ChatActor.CLIENT}
                    isSelected={selectedMessages.includes(chat.id)}
                    onSelect={handleMessageSelect}
                  />
                );
              })
            )}
          </Box>
          <Divider />
          <Box
            component="form"
            sx={{ display: 'flex', p: 2, borderTop: '1px solid #ccc' }}
            onSubmit={(e: any) => {
              e.preventDefault();
              handleSendReply();
            }}
          >
            <TextField
              fullWidth
              value={replyMessage}
              onChange={(e) => setReplyMessage(e.target.value)}
              placeholder="Type a reply..."
            />
            <IconButton onClick={handleOpenSavedMessagesList} sx={{ ml: 1 }}>
              <BookmarkIcon />
            </IconButton>
            <Button type="submit" variant="contained" startIcon={<SendIcon />} sx={{ ml: 1 }}>
              Send
            </Button>
          </Box>
          {/* Render the list modal */}
          <SavedMessagesListModal
            open={savedListOpen}
            onClose={() => setSavedListOpen(false)}
            actor={selectedActor || ''}
            onSelectMessage={handleSelectSavedMessage}
            onGoToSettings={() => {
              setSavedListOpen(false);
              setModalOpen(true);
            }}
          />
          {/* Render the settings modal (existing SavedMessagesModal) */}
          <SavedMessagesModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            actor={selectedActor || undefined}
            onRefresh={function (): void {
              throw new Error('Function not implemented.');
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
