import styled from '@emotion/styled';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Grid, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { DateField, FunctionField, Labeled, TextField, usePermissions } from 'react-admin';
import { CallClientButton } from '../../../components/twilio/CallClientButton';
import dataProvider from '../../../config/dataProvider';
import { hasPermission } from '../../../lib/SGHelper';

export const BasicDetails = (props: {
  data: any;
  stateFilingDetails: any;
  einDetails: any;
  registeredAgentDetails: any;
}) => {
  const subscribed = props.data?.subscriptionStatus || 'UNPAID';
  const existingCompany = !!props.data?.existingCompany;
  const { permissions } = usePermissions();
  const showPii = hasPermission(
    [
      'ROLE_SUPER_ADMIN',
      'ROLE_SALES',
      'ROLE_GROWTH_HACKER',
      'ROLE_EIN_SERVICE_STARTGLOBAL',
      'ROLE_SDR',
    ],
    permissions
  );
  const isImpersonator = hasPermission(['ROLE_ADMIN_USER_IMPERSONATOR'], permissions);
  const openAsImpersonator = () => {
    if (isImpersonator) {
      const url = dataProvider.getImpersonateUrl(null, {
        id: props.data.id,
        uuid: props.data.uuid,
      });
      if (url) {
        window.open(url, '_blank');
      } else {
        alert('Oh no. Some error occured. Please try again later.');
      }
    } else {
      alert('Oh no. Some error occured. Please try again later.');
    }
  };
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={8}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              {!props?.stateFilingDetails?.dateOfFormationString && (
                <Labeled label="Created On">
                  <DateField
                    record={props.data}
                    showTime
                    options={{ year: 'numeric', month: 'long', day: 'numeric' }}
                    source="createdDate"
                  ></DateField>
                </Labeled>
              )}
            </Grid>
            {showPii && (
              <Grid item xs={12} sm={12} md={6}>
                <Labeled label="Email">
                  <TextField record={props.data} source="email"></TextField>
                </Labeled>
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="Company">
                <FunctionField
                  record={props.data}
                  render={(record: { companyName: any; companyNameType: any }) =>
                    `${record.companyName} ${record.companyNameType}`
                  }
                />
              </Labeled>
              {isImpersonator && (
                <OpenInNewIcon
                  sx={{
                    width: '1.5rem',
                    cursor: 'pointer',
                    color: 'primary.main',
                  }}
                  onClick={openAsImpersonator}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="Type">
                <TextField record={props.data} source="companyType"></TextField>
              </Labeled>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="State">
                <TextField record={props.data} source="state"></TextField>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="Website">
                <TextField record={props.data} source="website"></TextField>
              </Labeled>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="Payment">
                <Chip
                  label={props.data?.paymentStatus}
                  color={getVariantBasedOnPaymentStatus(props.data?.paymentStatus)}
                  variant="filled"
                />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="Incorporation Status">
                <Typography variant="subtitle1" gutterBottom>
                  <Chip
                    label={props.data?.incorporationStatus}
                    color={getVariantBasedOnIncorporationStatus(props.data?.incorporationStatus)}
                    variant="filled"
                  />
                </Typography>
              </Labeled>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Labeled label="Description">
                <TextField record={props.data} source="businessDescription"></TextField>
              </Labeled>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="Industry">
                <TextField record={props.data} source="industry"></TextField>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="Franchise">
                <Typography variant="subtitle1" gutterBottom>
                  <Chip
                    label={getFranchiseName(props.data?.franchiseId)}
                    color="info"
                    variant="filled"
                  />
                </Typography>
              </Labeled>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="Onboarding Type">
                <Typography variant="subtitle1" gutterBottom>
                  <Chip
                    label={existingCompany ? 'Existing Company' : 'New Company'}
                    color="info"
                    variant="filled"
                  />
                </Typography>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="Subscription Status">
                <Typography variant="subtitle1" gutterBottom>
                  <Chip
                    label={subscribed}
                    color={subscribed === 'ACTIVE' ? 'info' : 'error'}
                    variant="filled"
                  />
                </Typography>
              </Labeled>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="Internal Ref.">
                <TextField record={props.data} source="id"></TextField>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={6}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              {props?.stateFilingDetails?.dateOfFormationString && (
                <Labeled label="Date of Formation">
                  <TextField
                    record={props.stateFilingDetails}
                    source="dateOfFormationString"
                  ></TextField>
                </Labeled>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {props?.einDetails?.ein && (
                <Labeled label="EIN">
                  <TextField record={props.einDetails} source="ein"></TextField>
                </Labeled>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Label>Responsible Party</Label>
          <Box>
            <Typography>
              {props.data?.responsiblePartyDetails?.firstName}{' '}
              {props.data?.responsiblePartyDetails?.lastName}
            </Typography>
            <Typography>{props.data?.responsiblePartyDetails?.addressLine1}</Typography>
            <Typography>{props.data?.responsiblePartyDetails?.city}</Typography>
            <Typography>
              {props.data?.responsiblePartyDetails?.stateOrProvince}{' '}
              {props.data?.responsiblePartyDetails?.postalCode}
            </Typography>
            <Typography>{props.data?.responsiblePartyDetails?.country}</Typography>
            <Typography>{props.data?.responsiblePartyDetails?.tel}</Typography>
          </Box>
          <Box>
            {props.data?.responsiblePartyDetails?.tel && (
              <CallClientButton
                tel={props.data?.responsiblePartyDetails?.tel}
                companyId={props.data?.id}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Spacer />
      {props.registeredAgentDetails?.addressLine1 && (
        <>
          <div>
            <Label>Registered Agent</Label>
            <Box>
              <Typography>{props.registeredAgentDetails.name} </Typography>
            </Box>
          </div>
          <Spacer />
          <div>
            <Label>US Mailing Address</Label>
            <div style={{ marginTop: '0.2em' }}>
              <Box>
                <Typography variant="body2">
                  {props.registeredAgentDetails?.addressLine1}
                </Typography>
                <Typography variant="body2">{props.registeredAgentDetails?.city}</Typography>
                <Typography variant="body2">
                  {props.registeredAgentDetails?.stateOrProvince}{' '}
                  {props.registeredAgentDetails?.postalCode} {props.registeredAgentDetails?.country}
                </Typography>
              </Box>
            </div>
          </div>
          <Spacer />{' '}
        </>
      )}
      {props.data?.owners?.length > 0 && (
        <>
          <Grid container spacing={1}>
            <Label>Owners</Label>
          </Grid>
          {props.data.owners.map((o: any) => {
            return (
              <Grid item xs={12} sm={12} md={6}>
                <Typography>
                  {o.firstName} {o.lastName} {o.percent}%
                </Typography>
              </Grid>
            );
          })}
        </>
      )}
      {props.data?.directors?.length > 0 && (
        <>
          <Grid container spacing={1}>
            <Grid xs={6}>
              <Label>Directors</Label>
              {props.data.directors.map((o: any) => {
                return (
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography>
                      {o.firstName} {o.lastName}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
            <Grid xs={6}>
              <Label>Officers and Roles</Label>
              <Grid container item xs={12}>
                {props.data.officers.map((o: any) => {
                  return (
                    <>
                      <Grid item xs={6}>
                        <Typography>{o.role}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{o.fullName}</Typography>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Spacer />
    </Box>
  );
};
const Spacer = () => <Box m={1}>&nbsp;</Box>;

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;

export const getVariantBasedOnPaymentStatus = (d: any) => {
  switch (d + '') {
    case 'PAID':
      return 'success';
    case 'NOT_PAID':
      return 'error';
    default:
      return 'info';
  }
};

export const getFranchiseName = (id: any) => {
  switch (id) {
    case 1:
      return 'TRYSTART';
    case 3:
      return 'StartGlobal';
    case 4:
      return 'Publicnote';
    case 5:
      return 'Alphafilings';
    case 6:
      return 'SmartStewards';
    case 7:
      return 'TitaniaJames';
    case 8:
      return 'Startupreseau';
    case 9:
      return 'Dentons';
    case 10:
      return 'Cooley';
    case 11:
      return 'Fistart';
    case 12:
      return 'Gowling';
    case 13:
      return 'TailorBrands';
    case 14:
      return 'Wyomingly';
    default:
      return 'Error';
  }
};

export const getVariantBasedOnIncorporationStatus = (d: any) => {
  switch (d + '') {
    case 'PENDING_PAYMENT':
      return 'error';
    case 'PENDING_VERIFICATION':
      return 'warning';
    case 'STARTED':
      return 'success';
    case 'COMPLETED':
      return 'success';
    case 'REJECTED':
      return 'info';
    case 'FREEZED':
      return 'error';
    case 'ONHOLD':
      return 'error';
    default:
      return 'info';
  }
};
