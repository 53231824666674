import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import dataProvider from '../../../../config/dataProvider';

export const ConnectedBankAccounts = ({ companyId }: { companyId: number }) => {
  const [expanded, setExpanded] = useState(true);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const notify = useNotify();

  const loadAccounts = async () => {
    setLoading(true);
    try {
      const response = await dataProvider.get(
        `company_bookkeeping_master/company/${companyId}/connected-accounts`,
        {}
      );
      console.log({
        data: response.data,
      });
      setAccounts(response.data || []);
    } catch (error) {
      notify('Failed to load bank accounts', { type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAccounts();
  }, [companyId]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleRowClick = (account: any) => {
    setSelectedAccount(account);
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setSelectedAccount(null);
  };

  const handleConfirmYes = async () => {
    try {
      await dataProvider.post(
        `company_bookkeeping_master/company/${companyId}/connected-accounts/remove`,
        {
          data: {
            item_id: selectedAccount.item_id,
          },
        }
      );
      notify('Bank account removed successfully', { type: 'success' });
      loadAccounts();
    } catch (error) {
      notify('Failed to remove bank account', { type: 'error' });
    }
    handleConfirmClose();
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Connected Bank Accounts
          </Typography>
        }
        onClick={handleExpandClick}
        style={{ cursor: 'pointer' }}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {loading ? (
            <Typography variant="body2">Loading...</Typography>
          ) : accounts.length === 0 ? (
            <Typography variant="body2">You do not have any connected bank accounts.</Typography>
          ) : (
            <Grid container spacing={2}>
              {/* Header Row */}
              <Grid item xs={1}>
                <Typography variant="subtitle2">#</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">Bank</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">Connected On</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">Actions</Typography>
              </Grid>
              {/* Data Rows */}
              {accounts.map((account, index) => (
                <React.Fragment key={account.item_id}>
                  <Grid item xs={1}>
                    <Typography variant="body2">{index + 1}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    onClick={() => handleRowClick(account)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Typography variant="body2">{account.institution_name}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    onClick={() => handleRowClick(account)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Typography variant="body2">{account.createdDate}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Tooltip title="Remove">
                      <IconButton onClick={() => handleRowClick(account)}>
                        <DeleteIcon style={{ color: 'red' }} />
                      </IconButton>
                    </Tooltip>
                    {/* Hidden itemId field */}
                    {/* <span style={{ display: 'none' }}>{account.itemId}</span> */}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          )}
        </CardContent>
      </Collapse>
      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Do you really want to remove this bank account? This will remove all the existing
            transactions from Bookkeeper
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmYes} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
