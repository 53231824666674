import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Avatar, Box, Typography } from '@mui/material';
import { ChatActor } from './ChatActor';

// Helper to format timestamps
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  const isSameYear = date.getFullYear() === now.getFullYear();

  const options: Intl.DateTimeFormatOptions = isSameYear
    ? { month: 'short', day: 'numeric' }
    : { year: 'numeric', month: 'short', day: 'numeric' };

  return date.toLocaleDateString('en-US', options);
};

interface ChatMessageProps {
  id: number;
  onToggleMessage: (id: number) => void;
  marked?: boolean;
  sendFrom: string;
  type: string;
  message: string;
  createdDate: string;
  last?: boolean;
  actionMessage?: string;
  isSelectable?: boolean;
  isSelected?: boolean;
  onSelect?: (id: number) => void;
}

export const ChatMessage = (props: ChatMessageProps) => {
  const {
    id,
    onToggleMessage,
    marked,
    sendFrom,
    type,
    message,
    createdDate,
    last,
    actionMessage,
    isSelectable = false,
    isSelected = false,
    onSelect,
  } = props;
  const isClient = sendFrom === ChatActor.CLIENT;
  const backgroundColor = isClient ? '#ffe6e6' : '#e6f9e6';

  const handleSelect = (e: React.MouseEvent) => {
    e.stopPropagation();
    onSelect && onSelect(id);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: isClient ? 'flex-start' : 'flex-end',
        mb: 2,
      }}
      // When clicked, toggle selection (via onSelect) if selectable
      onClick={() => (isSelectable ? onSelect && onSelect(id) : onToggleMessage(id))}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isClient ? 'row' : 'row-reverse',
          alignItems: 'center',
          gap: '0.5em',
          maxWidth: '80%',
        }}
      >
        <Avatar
          sx={{
            width: 24,
            height: 24,
            bgcolor: isClient ? 'error.light' : 'success.light',
          }}
        >
          {isClient ? <PersonIcon fontSize="small" /> : <SupportAgentIcon fontSize="small" />}
        </Avatar>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              cursor: 'pointer',
              border: isSelected ? '1px solid purple' : '1px solid #ddd',
              padding: '10px 16px',
              background: backgroundColor,
              borderRadius: '8px',
              wordBreak: 'break-word',
              transition: 'border 0.3s ease',
            }}
          >
            {type === 'CHAT' && <span>{message}</span>}
          </Box>
          {isSelectable && isSelected && (
            <CheckCircleIcon
              sx={{
                ml: 1,
                fontSize: 20,
                color: 'purple',
                transition: 'opacity 0.3s ease',
              }}
            />
          )}
        </Box>
      </Box>
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{
          alignSelf: isClient ? 'flex-start' : 'flex-end',
          ml: isClient ? 4 : 0,
          mr: isClient ? 0 : 4,
          mt: 0.5,
        }}
      >
        {formatDate(createdDate)}
        {actionMessage && ` | ${actionMessage}`}
      </Typography>
    </Box>
  );
};
