import HistoryIcon from '@mui/icons-material/History';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { parse } from 'papaparse';
import React, { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { useDropzone } from 'react-dropzone';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

const predefinedLists = [
  { id: 'LIST_OF_FEDERAL_TAX_DUE_MARCH_15', name: 'List of all federal tax due (March 15)' },
  { id: 'LIST_OF_FEDERAL_TAX_DUE_APRIL_18', name: 'List of all federal tax due (April 18)' },
  {
    id: 'LIST_OF_FRANCHISE_TAX_DUE_THIS_WEEK',
    name: 'List of all franchise tax due (next 7 days)',
  },
  {
    id: 'LIST_OF_FRANCHISE_TAX_DUE_NEXT_WEEK',
    name: 'List of all franchise tax due (next 14 days)',
  },
  {
    id: 'LIST_OF_FRANCHISE_TAX_DUE_THIS_MONTH',
    name: 'List of all franchise tax due (next 30 days)',
  },
  {
    id: 'LIST_OF_FRANCHISE_TAX_DUE_NEXT_MONTH',
    name: 'List of all franchise tax due (next 60 days)',
  },
  { id: 'LIST_OF_RA_DUE_THIS_WEEK', name: 'List of all RA due (next 7 days)' },
  { id: 'LIST_OF_RA_DUE_NEXT_WEEK', name: 'List of all RA due (next 14 days)' },
  { id: 'LIST_OF_RA_DUE_THIS_MONTH', name: 'List of all RA due (next 30 days)' },
  { id: 'LIST_OF_RA_DUE_NEXT_MONTH', name: 'List of all RA due (neext 60 days)' },
];

type Template = {
  id: string;
  name: string;
  activeResourceId: string;
  createdAt: string;
  updatedAt: string;
  supportedPlatforms: string[];
};

export const Whatsapp = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [selectedList, setSelectedList] = useState<string>('');
  const [customers, setCustomers] = useState<any[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<any[]>([]);
  const [openHistory, setOpenHistory] = useState(false);
  const [openCustomerList, setOpenCustomerList] = useState(false);
  const [history, setHistory] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [csvUploaded, setCsvUploaded] = useState(false);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [isTemplatesLoading, setIsTemplatesLoading] = useState(true);
  const [listCounts, setListCounts] = useState<{ [key: string]: number }>({});
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [csvMapping, setCsvMapping] = useState({
    companyId: '',
    company: '',
    firstName: '',
    tel: '',
  });
  const [openCsvMapping, setOpenCsvMapping] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleTemplateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTemplate(event.target.value);
  };

  const handleListChange = (event: SelectChangeEvent<string>) => {
    setSelectedList(event.target.value as string);
  };

  const handleFileUpload = (acceptedFiles: File[]) => {
    if (!selectedTemplate) {
      notify('Please select a template before uploading a CSV file', { type: 'error' });
      return;
    }
    const file = acceptedFiles[0];
    if (file) {
      setCsvUploaded(true);
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvData = event.target?.result as string;
        const parsedData = parse(csvData, { header: true });
        setCsvHeaders(parsedData.meta.fields || []);
        setCsvData(parsedData.data);
        setOpenCsvMapping(true);
      };
      reader.readAsText(file);
    }
  };

  const handleCustomerSelect = (customerId: string) => {
    setSelectedCustomers((prev) =>
      prev.includes(customerId) ? prev.filter((id) => id !== customerId) : [...prev, customerId]
    );
  };

  const handleSelectAll = () => {
    setSelectedCustomers(customers.map((customer) => customer.companyId));
  };

  const handleDeselectAll = () => {
    setSelectedCustomers([]);
  };

  const handleSendMessages = async () => {
    const data = selectedCustomers.map((customerId) => {
      const customer = customers.find((c) => c.companyId === customerId);
      const selectedTemplateDetails = templates.find(
        (template) => template.id === selectedTemplate
      );
      return {
        companyId: customer.companyId,
        tel: customer.tel,
        templateId: selectedTemplateDetails?.name,
        versionId: selectedTemplateDetails?.activeResourceId,
        projectId: selectedTemplateDetails?.id,
      };
    });

    dataProvider
      .post(`whatsapp/send-messages`, {
        data,
      })
      .then(({ data, error }: { data: any; error: any }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', { type: 'success' });
          setTimeout(() => {
            handleCloseCustomerList();
          }, 2000);
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error: any) => {
        notify(error.message || error.error || error.code || error, { type: 'error' });
      });
  };

  const fetchHistory = () => {
    setIsLoading(true);
    dataProvider
      .getList('whatsapp/history', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'DESC' },
        filter: {},
      })
      .then((response) => {
        setHistory(response.data);
      })
      .catch((err) => {
        console.log(err);
        notify('Failed to load history', { type: 'error' });
      })
      .finally(() => setIsLoading(false));
  };

  const fetchTemplates = () => {
    setIsTemplatesLoading(true);
    dataProvider
      .get('whatsapp/templates', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      })
      .then((response: any) => {
        const whatsappTemplates = response.data?.results.filter((template: Template) =>
          template.supportedPlatforms.includes('whatsapp')
        );
        setTemplates(whatsappTemplates);
      })
      .catch((err: any) => {
        console.log(err);
        notify('Failed to load templates', { type: 'error' });
      })
      .finally(() => setIsTemplatesLoading(false));
  };

  const fetchListCounts = () => {
    dataProvider
      .get('data/extract/count')
      .then((response: any) => {
        setListCounts(response.data);
      })
      .catch((err: any) => {
        console.log(err);
        notify('Failed to load list counts', { type: 'error' });
      });
  };

  React.useEffect(() => {
    fetchTemplates();
    fetchListCounts();
  }, []);

  const handleOpenHistory = () => {
    setOpenHistory(true);
    fetchHistory();
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  const handleOpenCustomerList = () => {
    if (!selectedTemplate) {
      notify('Please select a template', { type: 'error' });
      return;
    }
    if (!selectedList && !csvUploaded) {
      notify('Please select a user list or upload a CSV file', { type: 'error' });
      return;
    }

    if (selectedList) {
      setIsLoading(true);
      dataProvider
        .get('data/extract', {
          filter: { list: selectedList, type: 'WHATSAPP' },
        })
        .then((response: any) => {
          setCustomers(response.data);
          setOpenCustomerList(true);
        })
        .catch((err: any) => {
          console.log(err);
          notify('Failed to load customer list', { type: 'error' });
        })
        .finally(() => setIsLoading(false));
    } else {
      setOpenCustomerList(true);
    }
  };

  const handleCloseCustomerList = () => {
    setOpenCustomerList(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCsvMappingChange = (field: string, value: string) => {
    setCsvMapping((prev) => ({ ...prev, [field]: value }));
  };

  const handleCsvMappingContinue = () => {
    const mappedCustomers = csvData.map((row) => ({
      companyId: row[csvMapping.companyId],
      company: row[csvMapping.company],
      firstName: row[csvMapping.firstName],
      tel: row[csvMapping.tel],
    }));
    setCustomers(mappedCustomers);
    setOpenCsvMapping(false);
    setOpenCustomerList(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    accept: ['text/csv'],
    multiple: false,
  });

  const handleDownloadTemplate = () => {
    const sampleData = [
      ['companyId', 'company', 'firstName', 'tel'],
      ['123', 'Company A', 'John', '+123 456 7890'],
      ['456', 'Company B', 'Jane', '+10987654321'],
    ];
    const csvContent = sampleData.map((e) => e.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'sample_template.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const Row = ({ index, style }: ListChildComponentProps) => {
    const customer = customers[index];
    const isSelected = selectedCustomers.includes(customer.companyId);
    return (
      <TableRow
        style={style}
        key={customer.companyId}
        sx={{
          backgroundColor: isSelected ? '#e0f7fa' : index % 2 === 0 ? '#f9f9f9' : '#ffffff',
        }}
      >
        <TableCell sx={{ width: '5%' }}>
          <Checkbox
            checked={isSelected}
            onChange={() => handleCustomerSelect(customer.companyId)}
          />
        </TableCell>
        <TableCell sx={{ width: '10%' }}>{customer.companyId}</TableCell>
        <TableCell sx={{ width: '45%' }}>{customer.company}</TableCell>
        <TableCell sx={{ width: '20%' }}>{customer.firstName}</TableCell>
        <TableCell sx={{ width: '20%' }}>{customer.tel}</TableCell>
      </TableRow>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Send WhatsApp Messages
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6">Select Template</Typography>
          {isTemplatesLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : (
            <RadioGroup
              value={selectedTemplate}
              //for some erason, onChange is not triggering, so we added onClick on Card
              onChange={handleTemplateChange}
              name="template-group"
            >
              <Grid container spacing={2}>
                {templates.map((template) => (
                  <Grid item xs={12} key={template.id}>
                    <Card
                      variant="outlined"
                      sx={{
                        '&:hover': { backgroundColor: '#f0f0f0' },
                        ...(selectedTemplate === template.id && {
                          borderColor: 'primary.main',
                          backgroundColor: '#e0f7fa',
                        }),
                      }}
                      onClick={() => {
                        setSelectedTemplate(template.id);
                        console.log(template.id);
                      }}
                    >
                      <CardActionArea>
                        <CardContent>
                          <FormControlLabel
                            value={template.id}
                            control={<Radio />}
                            label={
                              <Box>
                                <Typography variant="body1">{template.name}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                  Created: {new Date(template?.createdAt)?.toLocaleDateString()}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  Updated: {new Date(template?.updatedAt)?.toLocaleDateString()}
                                </Typography>
                              </Box>
                            }
                          />
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Select User List</Typography>
          <FormControl fullWidth>
            <InputLabel id="user-list-label">User List</InputLabel>
            <Select
              labelId="user-list-label"
              value={selectedList}
              onChange={handleListChange}
              label="User List"
            >
              {predefinedLists.map((list) => (
                <MenuItem key={list.id} value={list.id}>
                  <Box display="flex" justifyContent="space-between" width="100%">
                    <span>{list.name}</span>
                    <span style={{ color: 'gray' }}>({listCounts[list.id] || 0})</span>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box mt={2}>
            <Typography variant="h6">Upload CSV</Typography>
            <Box
              {...getRootProps()}
              sx={{
                border: '2px dashed #ccc',
                p: 2,
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              <input {...getInputProps()} />
              <p>Drag & drop your CSV file here, or click to select file</p>
            </Box>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDownloadTemplate}
              sx={{ mt: 2, opacity: 0.7 }}
            >
              Download Sample CSV Template
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={handleOpenCustomerList}>
            Next
          </Button>
        </Grid>
      </Grid>
      <IconButton onClick={handleOpenHistory} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <HistoryIcon />
      </IconButton>
      <Dialog open={openCustomerList} onClose={handleCloseCustomerList} fullWidth maxWidth="lg">
        <DialogTitle>
          Customer List
          <Box component="span" sx={{ ml: 2, fontSize: '1rem', color: 'gray' }}>
            {selectedCustomers.length}/{customers.length}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            Template: {templates.find((template) => template.id === selectedTemplate)?.name}
          </Typography>
          <Button variant="contained" onClick={handleSelectAll}>
            Select All
          </Button>
          <Button variant="contained" onClick={handleDeselectAll} sx={{ ml: 2 }}>
            Deselect All
          </Button>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '5%' }}>Select</TableCell>
                  <TableCell sx={{ width: '10%' }}>Company ID</TableCell>
                  <TableCell sx={{ width: '45%' }}>Company</TableCell>
                  <TableCell sx={{ width: '20%' }}>First Name</TableCell>
                  <TableCell sx={{ width: '20%' }}>Telephone</TableCell>
                </TableRow>
              </TableHead>
              <AutoSizer disableHeight>
                {({ width }: { width: number }) => (
                  <FixedSizeList
                    height={400} // adjust as needed
                    width={width}
                    itemSize={50} // adjust row height if needed
                    itemCount={customers.length}
                  >
                    {Row}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCustomerList}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSendMessages}>
            Send Messages
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCsvMapping}
        onClose={() => setOpenCsvMapping(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Map CSV Columns</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="companyId-label">Company ID</InputLabel>
            <Select
              labelId="companyId-label"
              value={csvMapping.companyId}
              onChange={(e) => handleCsvMappingChange('companyId', e.target.value)}
            >
              {csvHeaders.map((header) => (
                <MenuItem key={header} value={header}>
                  {header}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="company-label">Company</InputLabel>
            <Select
              labelId="company-label"
              value={csvMapping.company}
              onChange={(e) => handleCsvMappingChange('company', e.target.value)}
            >
              {csvHeaders.map((header) => (
                <MenuItem key={header} value={header}>
                  {header}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="firstName-label">First Name</InputLabel>
            <Select
              labelId="firstName-label"
              value={csvMapping.firstName}
              onChange={(e) => handleCsvMappingChange('firstName', e.target.value)}
            >
              {csvHeaders.map((header) => (
                <MenuItem key={header} value={header}>
                  {header}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="tel-label">Telephone</InputLabel>
            <Select
              labelId="tel-label"
              value={csvMapping.tel}
              onChange={(e) => handleCsvMappingChange('tel', e.target.value)}
            >
              {csvHeaders.map((header) => (
                <MenuItem key={header} value={header}>
                  {header}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCsvMapping(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleCsvMappingContinue}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openHistory} onClose={handleCloseHistory} fullWidth maxWidth="lg">
        <DialogTitle>Message History</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Company ID</TableCell>
                    <TableCell>Telephone</TableCell>
                    <TableCell>Template ID</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Timestamp</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.map((row, index) => (
                    <TableRow
                      key={row.id}
                      style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff' }}
                    >
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.companyId}</TableCell>
                      <TableCell>{row.tel}</TableCell>
                      <TableCell>{row.templateId}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{row.timestamp}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
