import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { ChatActor } from '../modules/chat/ChatActor';
import { ChatSavedMessage } from '../modules/chat/ChatSavedMessage';

interface SavedMessagesModalProps {
  open: boolean;
  onClose: () => void;
  actor?: ChatActor;
  onRefresh: () => void;
}

const SavedMessagesModal: React.FC<SavedMessagesModalProps> = ({
  open,
  onClose,
  actor,
  onRefresh,
}) => {
  const dataProvider = useDataProvider();
  const [messages, setMessages] = useState<ChatSavedMessage[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editText, setEditText] = useState('');

  const loadMessages = () => {
    if (actor) {
      dataProvider
        .get(`chat_master/saved-messages/${actor}`)
        .then((res: any) => {
          setMessages(res.data.filter((m: ChatSavedMessage) => !m.isDeleted));
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (open && actor) {
      loadMessages();
    }
  }, [open, actor, dataProvider]);

  const handleAdd = () => {
    if (newMessage.trim()) {
      dataProvider
        .post('chat_master/saved-messages', { data: { message: newMessage.trim(), owner: actor } })
        .then(() => {
          setNewMessage('');
          loadMessages();
          onRefresh();
        })
        .catch((err: any) => console.error(err));
    }
  };

  const handleEdit = (id: number, text: string) => {
    setEditIndex(id);
    setEditText(text);
  };

  const handleEditSave = (id: number) => {
    if (editText.trim()) {
      dataProvider
        .post(`chat_master/saved-messages/${id}`, { data: { message: editText.trim() } })
        .then(() => {
          setEditIndex(null);
          setEditText('');
          loadMessages();
          onRefresh();
        })
        .catch((err: any) => console.error(err));
    }
  };

  const handleDelete = (id: number) => {
    dataProvider
      .post(`chat_master/saved-messages/${id}/delete`, { data: {} })
      .then(() => {
        loadMessages();
        onRefresh();
      })
      .catch((err: any) => console.error(err));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        Saved Messages Settings
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List>
          {messages.map((msg) => (
            <ListItem key={msg.id}>
              {editIndex === msg.id ? (
                <TextField
                  fullWidth
                  value={editText}
                  onChange={(e) => setEditText(e.target.value)}
                />
              ) : (
                <ListItemText primary={msg.message} />
              )}
              <ListItemSecondaryAction>
                {editIndex === msg.id ? (
                  <IconButton edge="end" onClick={() => handleEditSave(msg.id)}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <>
                    <IconButton edge="end" onClick={() => handleEdit(msg.id, msg.message)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton edge="end" onClick={() => handleDelete(msg.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <TextField
          fullWidth
          label="New Saved Message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          margin="normal"
        />
        <Button variant="contained" onClick={handleAdd}>
          Add Message
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default SavedMessagesModal;
