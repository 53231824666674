import CallEndIcon from '@mui/icons-material/CallEnd';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Call, Device } from '@twilio/voice-sdk';
import React, { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { CallHistory } from './CallHistory';

// Placeholder import for Twilio Voice SDK integration

type CallClientButtonProps = {
  companyId: string;
  tel: string;
};

export const CallClientButton: React.FC<CallClientButtonProps> = ({ companyId, tel }) => {
  const [open, setOpen] = useState(false);
  const [callStatus, setCallStatus] = useState<'idle' | 'connecting' | 'active'>('idle');
  const [muted, setMuted] = useState(false);
  const [device, setDevice] = useState<Device | null>(null);
  const [editableTel, setEditableTel] = useState(tel);
  const [eventLog, setEventLog] = useState<string[]>([]);
  const dataProvider = useDataProvider();
  const [tokenResponse, setTokenResponse] = useState<any>(null);
  const notify = useNotify();
  const [activeTab, setActiveTab] = useState<number>(0); // New state for tabs
  const [selectedRingtone, setSelectedRingtone] = useState<string>('');
  const [selectedSpeaker, setSelectedSpeaker] = useState<string>('');

  const [ringtoneDevices, setRingtoneDevices] = useState<any[]>([]);
  const [speakerDevices, setSpeakerDevices] = useState<any[]>([]);

  // Helper to push events to the log
  const logEvent = (msg: string) => {
    setEventLog((prev) => [msg, ...prev]);
  };

  // Modified useEffect to request audio permission before setting devices
  React.useEffect(() => {
    if (!open) return;
    async function initAudioDevices() {
      await navigator.mediaDevices.getUserMedia({ audio: true }); // Request permission
      if (device) {
        const availableDevices = device?.audio?.availableOutputDevices
          ? Array.from(device.audio.availableOutputDevices.values())
          : [];
        setRingtoneDevices(availableDevices);
        setSpeakerDevices(availableDevices);
        //show the properties of all the available devices MediaDeviceInfo
        console.log(availableDevices);
        const ringtoneDevice = device.audio?.ringtoneDevices.get();
        setSelectedRingtone(ringtoneDevice ? Array.from(ringtoneDevice)[0]?.deviceId || '' : '');
        const speakerDevice = device.audio?.speakerDevices.get();
        setSelectedSpeaker(speakerDevice ? Array.from(speakerDevice)[0]?.deviceId || '' : '');
      }
    }
    initAudioDevices();
  }, [device, open]);

  // Modified useEffect: Only initialize Twilio code when dialog is open
  React.useEffect(() => {
    if (!open) return; // Only run when dialog is open
    dataProvider
      .get(`call/twilio/token`, {})
      .then((data: any) => {
        const token = data.data.token;
        setTokenResponse(data.data);
        const device = new Device(token, {
          logLevel: 1,
          codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
        });
        // Add all event listeners
        device.on('ready', function () {
          notify('Twilio.Device Ready!', { type: 'info' });
          logEvent('Device ready');
        });
        device.on('registered', function () {
          notify('Twilio.Device registered!', { type: 'info' });
          logEvent('Device registered');
        });
        device.on('error', function (error) {
          notify('Twilio.Device Error: ' + error.message, { type: 'error' });
          logEvent('Device error: ' + error.message);
        });
        device.on('connect', function () {
          notify('Call connected!', { type: 'info' });
          logEvent('Call connected');
          setCallStatus('active');
        });
        device.on('disconnect', function () {
          notify('Call ended.');
          logEvent('Call disconnected');
          handleHangup(); // Call handleHangup if the other party hangs up
        });
        device.audio?.on('deviceChange', function () {
          const availableDevices = device.audio
            ? Array.from(device.audio.availableOutputDevices.values())
            : [];
          setRingtoneDevices(availableDevices);
          setSpeakerDevices(availableDevices);
        });
        device.register();
        setDevice(device);
      })
      .catch((error: any) => {
        console.log(error);
        notify('Failed to load Twilio chat', { type: 'error' });
        logEvent('Failed to load Twilio token');
      });
    // Optional: Cleanup when dialog is closed
    return () => {
      if (device) device.disconnectAll();
    };
  }, [open]);

  function updateUIAcceptedOutgoingCall(call: any) {
    notify('Call connected.');
  }

  function updateUIDisconnectedOutgoingCall() {
    notify('Call ended.');
    logEvent('Call disconnected');
    handleHangup(); // Call handleHangup if the other party hangs up
  }

  const disconnect = () => {
    if (!device) return notify('Device not ready', { type: 'error' });
    device.disconnectAll();
  };

  const handleCall = async () => {
    // TODO: Initiate call using @twilio/voice-sdk with companyId and tel
    setCallStatus('connecting');
    logEvent('Initiating call...');
    if (!device) return notify('Device not ready', { type: 'error' });
    var params = {
      companyId: companyId,

      // get the phone number to call from the DOM
      To: editableTel,
    };

    if (device) {
      // Twilio.Device.connect() returns a Call object
      const call = await device.connect({ params });

      // add listeners to the Call
      // "accepted" means the call has finished connecting and the state is now "open"
      call.on('accept', updateUIAcceptedOutgoingCall);
      call.on('disconnect', updateUIDisconnectedOutgoingCall);
      call.on('cancel', updateUIDisconnectedOutgoingCall);
    } else {
      notify('Unable to make call.', { type: 'error' });
    }
  };

  const handleHangup = () => {
    // TODO: End call via SDK methods
    disconnect();
    logEvent('Hanging up call');
    setCallStatus('idle');
    // setOpen(false);
  };

  const toggleMute = () => {
    // TODO: Invoke SDK mute toggle functionality
    setMuted((prev) => !prev);
    logEvent(muted ? 'Unmuted the call' : 'Muted the call');
  };

  // New handler to block closing on ESC and backdrop click
  const handleClose = (event: object, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<PhoneIcon />}
        size="large"
        onClick={() => setOpen(true)}
        style={{
          marginTop: '10px',
        }}
      >
        Call
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" /* enlarged dialog */>
        <DialogTitle>
          {/* New component added before the close button */}
          <Box
            sx={{
              cursor: 'pointer',
              color: 'primary.main',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginRight: '2rem',
            }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(`#/company-master-data/${companyId}`, '_blank');
            }}
          >
            <Typography variant="h6" sx={{ fontSize: '16px' }}>
              Open Company Details
            </Typography>
            <Box sx={{ cursor: 'pointer', color: 'primary.main' }}>
              <OpenInNewIcon />
            </Box>
          </Box>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {/* Modified Tabs usage */}
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          sx={{ justifyContent: 'flex-start' }} // Align tabs to the left
        >
          <Tab label="Call Info" />
          <Tab label="History" />
        </Tabs>
        <DialogContent>
          {activeTab === 0 ? (
            // Call Info tab content: existing Grid details moved inside
            <Grid container spacing={2}>
              {/* Column 1: Device and audio info */}
              <Grid item xs={4}>
                <Paper elevation={3} style={{ padding: '10px' }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Device Info
                  </Typography>
                  <Typography variant="body2">
                    Default Device: {device ? 'Active' : 'Not Ready'}
                  </Typography>
                  {/* New clickable list for Ringtone Devices */}
                  <Typography variant="subtitle1" gutterBottom style={{ marginTop: '10px' }}>
                    Ringtone Devices
                  </Typography>
                  <List dense>
                    {ringtoneDevices.map((d: any) => (
                      <ListItem
                        button
                        key={d.deviceId}
                        selected={selectedRingtone === d.deviceId}
                        onClick={() => {
                          setSelectedRingtone(d.deviceId);
                          device?.audio?.ringtoneDevices.set([d.deviceId]);
                        }}
                      >
                        <Typography variant="body2">{d.label}</Typography>
                      </ListItem>
                    ))}
                  </List>
                  {/* New clickable list for Speaker Devices */}
                  <Typography variant="subtitle1" gutterBottom style={{ marginTop: '10px' }}>
                    Speaker Devices
                  </Typography>
                  <List dense>
                    {speakerDevices.map((d: any) => (
                      <ListItem
                        button
                        key={d.deviceId}
                        selected={selectedSpeaker === d.deviceId}
                        onClick={() => {
                          setSelectedSpeaker(d.deviceId);
                          device?.audio?.speakerDevices.set([d.deviceId]);
                        }}
                      >
                        <Typography variant="body2">{d.label}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>
              {/* Column 2: Telephone input and call actions */}
              <Grid item xs={4}>
                <Paper elevation={3} style={{ padding: '10px' }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Telephone Number
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={editableTel}
                    onChange={(e) => setEditableTel(e.target.value)}
                  />
                  <Box mt={2} display="flex" justifyContent="flex-end" gap={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<PhoneIcon />}
                      onClick={handleCall}
                      disabled={callStatus === 'active' || callStatus === 'connecting'}
                    >
                      Call
                    </Button>
                    {callStatus !== 'idle' && (
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<CallEndIcon />}
                        onClick={handleHangup}
                      >
                        Hang Up
                      </Button>
                    )}
                  </Box>
                </Paper>
              </Grid>
              {/* Column 3: Event Log */}
              <Grid item xs={4}>
                <Paper
                  elevation={3}
                  style={{ padding: '10px', maxHeight: '200px', overflowY: 'auto' }}
                >
                  <Typography variant="subtitle1" gutterBottom>
                    Event Log
                  </Typography>
                  <List dense>
                    {eventLog.map((evt, index) => (
                      <ListItem key={index}>
                        <Typography variant="body2">{evt}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            // History tab content (currently blank)
            <CallHistory companyId={companyId} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
