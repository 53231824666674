import {
  Datagrid,
  ExportButton,
  FilterButton,
  FunctionField,
  RaRecord,
  TopToolbar,
  UrlField,
  usePermissions,
} from 'react-admin';

import List from '../../components/List';
import ReferenceField from '../../components/ReferenceField';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { hasPermission } from './../../lib/SGHelper';

const cpaConfig = {
  Filters: [<TextInput source="company" label="Company" alwaysOn />],
  filterDefaultValues: {},
};

const ListActions = () => {
  const { permissions } = usePermissions();

  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton maxResults={4000} />
    </TopToolbar>
  );
};
const TwilioCallRecordingList = () => {
  const { permissions } = usePermissions();

  const isCPA =
    hasPermission('ROLE_CPA_0128', permissions) ||
    hasPermission('ROLE_CPA_0988', permissions) ||
    hasPermission('ROLE_CPA_1', permissions) ||
    hasPermission('ROLE_CPA_2', permissions) ||
    hasPermission('ROLE_CPA_3', permissions);

  return (
    <List
      filters={cpaConfig.Filters}
      filterDefaultValues={cpaConfig.filterDefaultValues}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" />

        <ReferenceField
          source="companyId"
          label="Company"
          reference="company-master-data"
          link={(record: RaRecord, reference: string) => {
            return `/${reference}/${record.companyRef}?tab=federal-tax`;
          }}
        >
          <FunctionField
            label="Company"
            render={(record: { companyName: any; companyNameType: any }) =>
              `${record.companyName} ${record.companyNameType}`
            }
          />
        </ReferenceField>

        <UrlField source="recordingUrl" />
        <TextField source="recordingDuration" />
      </Datagrid>
    </List>
  );
};

export default TwilioCallRecordingList;
