// Helper function to clean strings.
export const clean = (str: string): string => str.replace(/\s+/g, ' ').trim();
export const cleanAmount = (str: string): number =>
  parseFloat(
    clean(str)
      .replace(/[$,€]/g, '') // Updated regex to replace €
      .replace(/\u00A0/g, '')
      .replace(/—|\u2013|\u2012|\u2212/g, '-')
  );

export const extractValue = (
  column: string[],
  index: number | number[],
  cleanFlag = true
): string => {
  let value = '';

  if (Array.isArray(index)) {
    value = index.map((i) => column[i]).join(' ');
  } else {
    value = column[index];
  }
  if (cleanFlag) {
    value = clean(value);
  }
  return value;
};
