import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';

interface ChatSavedMessageDTO {
  id: number;
  message: string;
  owner: string;
  isDeleted: boolean;
}

interface SavedMessagesListModalProps {
  open: boolean;
  onClose: () => void;
  onSelectMessage: (message: string) => void;
  onGoToSettings: () => void;
  actor: string;
}

const SavedMessagesListModal: React.FC<SavedMessagesListModalProps> = ({
  open,
  onClose,
  onSelectMessage,
  onGoToSettings,
  actor,
}) => {
  const dataProvider = useDataProvider();
  const [messages, setMessages] = useState<ChatSavedMessageDTO[]>([]);

  useEffect(() => {
    if (open && actor) {
      dataProvider
        .get(`chat_master/saved-messages/${actor}`)
        .then((res: any) => {
          setMessages(res.data.filter((m: ChatSavedMessageDTO) => !m.isDeleted));
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  }, [open, actor, dataProvider]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        Saved Messages
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {messages.length === 0 ? (
          <Alert severity="info">
            There are no saved messages. You can add or remove messages using the settings.
          </Alert>
        ) : (
          <>
            <List>
              {messages.map((msg) => (
                <ListItem key={msg.id} button onClick={() => onSelectMessage(msg.message)}>
                  <ListItemText primary={msg.message} />
                </ListItem>
              ))}
            </List>
          </>
        )}
        <Button variant="contained" onClick={onGoToSettings} fullWidth sx={{ mt: 2 }}>
          Add/Remove messages
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default SavedMessagesListModal;
