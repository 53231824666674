import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';

type CallHistoryProps = {
  companyId: string;
};

export const CallHistory: React.FC<CallHistoryProps> = ({ companyId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [history, setHistory] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dataProvider = useDataProvider();

  const fetchHistory = () => {
    setIsLoading(true);
    setError(false);
    dataProvider
      .getList('call/twilio', {
        pagination: { page: page + 1, perPage: rowsPerPage },
        sort: { field: 'id', order: 'DESC' },
        filter: { companyId: companyId },
      })
      .then((response) => {
        setHistory(response.data);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchHistory();
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return '-';
    return format(new Date(dateString), 'yyyy-MM-dd HH:mm:ss');
  };

  return (
    <Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">Failed to load call history.</Typography>
      ) : (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Caller</TableCell>
                  <TableCell>To Caller</TableCell>
                  <TableCell>Direction</TableCell>
                  <TableCell>Initiated At</TableCell>
                  <TableCell>Ringing At</TableCell>
                  <TableCell>Answered At</TableCell>
                  <TableCell>Completed At</TableCell>
                  <TableCell>Recording URL</TableCell>
                  <TableCell>Recording Duration</TableCell>
                  <TableCell>Remarks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((row, index) => (
                  <TableRow
                    key={row.id}
                    style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff' }}
                  >
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.caller}</TableCell>
                    <TableCell>{row.toCaller}</TableCell>
                    <TableCell>{row.direction}</TableCell>
                    <TableCell>{formatDate(row.initiatedAt)}</TableCell>
                    <TableCell>{formatDate(row.ringingAt)}</TableCell>
                    <TableCell>{formatDate(row.answeredAt)}</TableCell>
                    <TableCell>{formatDate(row.completedAt)}</TableCell>
                    <TableCell>
                      <a href={row.recordingUrl} target="_blank" rel="noopener noreferrer">
                        Recording
                      </a>
                    </TableCell>
                    <TableCell>{row.recordingDuration}</TableCell>
                    <TableCell>{row.remarks}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={history.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </Box>
  );
};
