import styled from '@emotion/styled';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';

export const SubscriptionCompliance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const dataProvider = useDataProvider();

  const fetchSubscriptions = () => {
    setIsLoading(true);
    setError(false);
    Promise.all([
      dataProvider.getList('subscribed_company_compliance_master', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      }),
    ])
      .then((values) => {
        setSubscriptions(values[0].data);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  // Assign priority:
  // 1 - if record.status is not ACTIVE
  // 2 - if record.status is ACTIVE but federalTaxStatus is not ACTIVE
  // 3 - if both are ACTIVE
  const getPriority = (r: any) => {
    if (r.status !== 'ACTIVE') return 1;
    if (r.federalTaxStatus !== 'ACTIVE') return 2;
    return 3;
  };

  const sortedSubscriptions = [...subscriptions].sort((a, b) => getPriority(a) - getPriority(b));

  return (
    <Wrapper>
      <HeaderWrap>
        <Header>Subscription Compliance</Header>
        <Tooltip title="Reload">
          <IconButton onClick={fetchSubscriptions}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </HeaderWrap>
      <BodyWrapper>
        {isLoading ? (
          <Centered>
            <CircularProgress />
          </Centered>
        ) : error ? (
          <Centered>
            <p>Error loading subscriptions.</p>
            <Tooltip title="Reload">
              <IconButton onClick={fetchSubscriptions}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Centered>
        ) : sortedSubscriptions && sortedSubscriptions.length ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Company Ref.</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Registered Agent</TableCell>
                  <TableCell>Franchise Tax</TableCell>
                  <TableCell>Federal Tax</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Subscription</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedSubscriptions.map((record) => {
                  let rowStyle = {};
                  if (record.status !== 'ACTIVE') {
                    rowStyle = { backgroundColor: '#f8d7da' };
                  } else if (record.federalTaxStatus !== 'ACTIVE') {
                    rowStyle = { backgroundColor: '#fff3cd' };
                  }
                  return (
                    <ClickableRow
                      key={record.id}
                      style={rowStyle}
                      onClick={() =>
                        window.open(`/#/company-master-data/${record.companyId}`, '_blank')
                      }
                    >
                      <TableCell>{record.companyId}</TableCell>
                      <TableCell>{record.companyName}</TableCell>
                      <TableCell>{record.email}</TableCell>
                      <TableCell>{record.state}</TableCell>
                      <TableCell>{record.registeredAgentStatus}</TableCell>
                      <TableCell>{record.franchiseTaxStatus}</TableCell>
                      <TableCell>{record.federalTaxStatus}</TableCell>
                      <TableCell>{record.status}</TableCell>
                      <TableCell>{record.subscriptionStatus}</TableCell>
                    </ClickableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoDataFound>No subscriptions found.</NoDataFound>
        )}
      </BodyWrapper>
    </Wrapper>
  );
};

export default SubscriptionCompliance;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const HeaderWrap = styled.div`
  display: flex;
  padding: 23px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #444c66;
`;

const BodyWrapper = styled.div`
  padding: 0px 32px;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

const NoDataFound = styled.div`
  font-size: 18px;
  color: #444c66;
  text-align: center;
`;

const ClickableRow = styled(TableRow)`
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
`;
