import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

// New helper component for copyable field with hover effect
const CopyableField = ({ value }: { value: string | number }) => {
  const notify = useNotify();
  const copyField = () => {
    navigator.clipboard.writeText(String(value)).then(() => {
      notify('Copied to clipboard', { type: 'info' });
    });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        p: 0.5,
        '&:hover': { cursor: 'pointer' },
        '&:hover .copy-icon': { display: 'inline-flex' },
        '&:hover .copyable-text': { backgroundColor: 'rgba(0,0,0,0.1)' },
      }}
    >
      <Typography variant="body1" component="span" className="copyable-text">
        {value}
      </Typography>
      <Tooltip title="Copy">
        <IconButton size="small" onClick={copyField} sx={{ display: 'none' }} className="copy-icon">
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export const AccountingAdditionalData = ({
  companyId,
  year,
  source,
}: {
  companyId: number;
  year: number;
  source: 'federal-tax' | 'bookkeeping';
}) => {
  if (source === 'federal-tax') {
    return <ShowFederalTaxAdditionalData companyId={companyId} year={year} source={source} />;
  }
  if (source === 'bookkeeping') {
    return <ShowBookkeepingAdditionalData companyId={companyId} year={year} source={source} />;
  }
  return null;
};

export const ShowFederalTaxAdditionalData = ({
  companyId,
  year,
  source,
}: {
  companyId: number;
  year: number;
  source: 'federal-tax' | 'bookkeeping';
}) => {
  const [isloading, setIsloading] = React.useState(false);
  const [data, setData] = React.useState<any>();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  React.useEffect(() => {
    setIsloading(true);
    const url = `federal-tax/company/${companyId}/additional-data/${year}`;
    dataProvider
      .get(url, {})
      .then(({ data, error }: { data: any; error: any }) => {
        console.log('federal-tax', data);
        //data comes in the ascending order.
        //But we like to keep the most recent order at the top
        if (error) {
          throw new Error(error);
        }
        setData(data);
      })
      .catch((error: { message: any; error: any; code: any }) => {
        notify(error.message || error.error || error.code || error, { type: 'error' });
        notify(error.message || error.error || error.code || error, { type: 'error' });
      })
      .finally(() => setIsloading(false));
  }, []);

  const copyData = () => {
    const jsonText = JSON.stringify(data, null, 2);
    navigator.clipboard.writeText(jsonText).then(() => {
      notify('Copied to clipboard', { type: 'info' });
    });
  };

  return (
    <Card>
      <CardHeader
        title="Company Additional Data"
        // action={
        //   <Tooltip title="Copy to clipboard">
        //     <IconButton onClick={copyData}>
        //       <ContentCopyIcon />
        //     </IconButton>
        //   </Tooltip>
        // }
      />
      <CardContent>
        {isloading ? (
          <Typography>Loading...</Typography>
        ) : data && data?.addressLine1 ? (
          <Grid container spacing={2}>
            {/* General Information */}
            <Grid item xs={12}>
              <Typography variant="h6">General Info</Typography>
              <Divider />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">Office/Staff/Physical Presence:</Typography>
            </Grid>
            {data.physicalPresenceDetails ? (
              <CopyableField value={data.physicalPresenceDetails} />
            ) : (
              <CopyableField value="No" />
            )}
            <Grid item xs={6}>
              <Typography variant="body1">Custom Tax Selection Details:</Typography>
            </Grid>
            {data.isCustomTaxSelectionCompleted ? (
              <CopyableField value={data.customTaxSelectionDetails} />
            ) : (
              <CopyableField value="No" />
            )}

            <Grid item xs={6}>
              <Typography variant="body1">Phone Number:</Typography>
            </Grid>
            <Grid item xs={6}>
              <CopyableField value={data.phoneNumber} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Location of business:</Typography>
            </Grid>
            <Grid item xs={6}>
              <CopyableField
                value={`${data.addressLine1} ${data.addressLine2}, ${data.city}, ${data.stateOrProvince}, ${data.country} ${data.postalCode}`}
              />
            </Grid>
            {/* Conditionally render Custom Tax Selection Details */}

            {/* Owners Section */}
            <Grid item xs={12}>
              <Typography variant="h6">Owners</Typography>
              <Divider />
            </Grid>
            {data.owners && data.owners.length > 0 ? (
              data.owners.map((owner: any, index: number) => (
                <React.Fragment key={index}>
                  <Grid item xs={6}>
                    <Typography variant="body1">Name:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <CopyableField value={`${owner.firstName} ${owner.lastName}`} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">Telephone:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <CopyableField value={owner.tel} />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Typography variant="body1">Email:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <CopyableField value={owner.email} />
                  </Grid> */}
                  <Grid item xs={6}>
                    <Typography variant="body1">Address:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <CopyableField
                      value={`${owner.addressLine1}, ${owner.city}, ${owner.stateOrProvince}, ${owner.country} ${owner.postalCode}`}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">Percent:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <CopyableField value={`${owner.percent}%`} />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Typography variant="body1">Date of Birth:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <CopyableField value={owner.dateOfBirth} />
                  </Grid> */}
                </React.Fragment>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1">No owners data available.</Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <Typography>No data available.</Typography>
        )}
      </CardContent>
    </Card>
  );
};

export const ShowBookkeepingAdditionalData = ({
  companyId,
  year,
  source,
}: {
  companyId: number;
  year: number;
  source: 'federal-tax' | 'bookkeeping';
}) => {
  const [isloading, setIsloading] = React.useState(false);
  const [data, setData] = React.useState<any>();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  React.useEffect(() => {
    setIsloading(true);
    const url = `federal-tax/company/${companyId}/additional-data/${year}`;
    dataProvider
      .get(url, {})
      .then(({ data, error }: { data: any; error: any }) => {
        console.log('federal-tax', data);
        //data comes in the ascending order.
        //But we like to keep the most recent order at the top
        if (error) {
          throw new Error(error);
        }
        setData(data);
      })
      .catch((error: { message: any; error: any; code: any }) => {
        notify(error.message || error.error || error.code || error, { type: 'error' });
        notify(error.message || error.error || error.code || error, { type: 'error' });
      })
      .finally(() => setIsloading(false));
  }, []);

  const copyData = () => {
    const jsonText = JSON.stringify(data, null, 2);
    navigator.clipboard.writeText(jsonText).then(() => {
      notify('Copied to clipboard', { type: 'info' });
    });
  };

  return (
    <Card>
      <CardHeader
        title="Company Additional Data"
        // action={
        //   <Tooltip title="Copy to clipboard">
        //     <IconButton onClick={copyData}>
        //       <ContentCopyIcon />
        //     </IconButton>
        //   </Tooltip>
        // }
      />
      <CardContent>
        {isloading ? (
          <Typography>Loading...</Typography>
        ) : data && data?.addressLine1 ? (
          <Grid container spacing={2}>
            {/* General Information */}

            <Grid item xs={6}>
              <Typography variant="body1">Initial Disclosed Capital:</Typography>
            </Grid>
            <Grid item xs={6}>
              <CopyableField value={data.initialDisclosedCapital} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Bank Balance:</Typography>
            </Grid>
            <Grid item xs={6}>
              <CopyableField value={data.bankBalance} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Total Assets:</Typography>
            </Grid>
            <Grid item xs={6}>
              <CopyableField value={data.totalAssets} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Total Liabilities:</Typography>
            </Grid>
            <Grid item xs={6}>
              <CopyableField value={data.totalLiabilities} />
            </Grid>
          </Grid>
        ) : (
          <Typography>No data available.</Typography>
        )}
      </CardContent>
    </Card>
  );
};
