import { Admin, CustomRoutes, ListGuesser, Resource } from 'react-admin';
import './App.css';
import authProvider from './config/authProvider';
import dataProvider from './config/dataProvider';
import CompanyArchivedMasterResource from './modules/company-archived-master';
import CompanyMaster from './modules/company-master-data';
import KpiInstanceDetail from './modules/dashboard/kpi-dashboard/KpiInstanceDetail';
import KpiPayments from './modules/dashboard/kpi-payments/KpiPayments';
import FranchiseChangeLogResource from './modules/franchise-change-logs';
import AdsMaster from './modules/sg-ads-master';
import EmployeeCompensationMasterDataResource from './modules/sg-employee-compensation-master';
import EmployeeMasterDataResource from './modules/sg-employee-master';
import ExpenseMaster from './modules/sg-expense-master';

import RegisteredAgentTransfer from './modules/administration/ra-transfer';
import RegAgentPayments from './modules/administration/registered-agent-payments';
import CompanyReinstatements from './modules/administration/reinstatements';
import KpiConfigParam from './modules/kpi-configuration-parameters';
import KpiInstanceParam from './modules/kpi-instance-parameters';

import CompanyDissolutions from './modules/administration/dissolution';

import ActionItems from './modules/action-items';
import CompanyArchivedOrders from './modules/company-archived-orders/company-archived-orders';
import CompanyPendingOrders from './modules/company-pending-orders/company-pending-orders';
import PassportList from './modules/passport';
import SS4List from './modules/ss4';
import SS4PendingGenerationList from './modules/ss4-pending-generation';
import StateFilingServiceList from './modules/state-filing-service';

import { Route } from 'react-router';
import { Login } from './components/Login';
import { Layout } from './layout';
import StateTax from './modules/accounting/state-tax';
import AddonProductMaster from './modules/addon-products';
import FederalTaxBusinessExtensionAddon from './modules/addons/business-extension';
import FederalTaxIndividualExtensionAddon from './modules/addons/individual-extension';
import AddonMaster from './modules/addons/master';
import BE12Survey from './modules/be-12-surveys';
import BoiMaster from './modules/boi-master';
import { Chat } from './modules/chat/Chat';
import CompanyBookkeeping from './modules/company-bookkeeping';
import CompanyBookkeepingChat from './modules/company-bookkeeping-chats';
import CompanyLeadsList from './modules/company-leads';
import EinService from './modules/ein-service';
import EmailRegistration from './modules/email-registration-details';
import FaxLogs from './modules/fax-logs';
import FederalTaxBookkeepingWorkflow from './modules/federal-tax-bookkeeping-workflow';
import FederalTaxChat from './modules/federal-tax-chats';
import FederalTaxChats from './modules/federal-tax-chats/FederalTaxChats';
import FederalTaxSummary from './modules/federal-tax-summary';
import FranchiseMaster from './modules/franchise-master';
import LocalBatch from './modules/local-batches';
import MailReceivedLogs from './modules/mail-received-logs';
import PhoneNumberRegistrationDetailList from './modules/phone-number-registration-details';
import Reports from './modules/reports-management/reports/Reports';
import SubscriptionMasterData from './modules/sg-view-subscription-master-data';

import * as AccountingCompanySummaryDetails from './modules/accounting/accounting-company-summary';
import * as AccountingSummaryDetails from './modules/accounting/accounting-summary';
import ExistingCompanyOnboarding from './modules/approvals/existing-companies';
import StateTaxApprovalList from './modules/approvals/state-tax';
import { BankStatements } from './modules/bank-statements/BankStatements';
import EmailBlackListDetails from './modules/email-blacklist';
import EntityManagement from './modules/entity-management';
import FederalTaxPenalty from './modules/federal-tax-penalty';
import MercuryView from './modules/mercury';
import { SubscriptionCompliance } from './modules/monitoring/SubscriptionCompliance';
import OperatingAgreementAddonList from './modules/operating-agreement-addon';
import StateFilings from './modules/state-filings';
import TaxEstimatorAddonList from './modules/tax-estimator-addon';
import TwilioCallRecording from './modules/twilio-call-recordings';
import CompanyUpcomingPaymentsView from './modules/upcoming-payments';
import { Whatsapp } from './modules/whatsapp/Whatsapp';
const ApprovalsMenu = [
  <Resource
    name="approvals/franchise-tax"
    options={{ label: 'State Tax', menuParent: 'company-header' }}
    list={StateTaxApprovalList.list}
  />,
  <Resource
    name="approvals/existing-company-onboarding"
    options={{ label: 'Existing Company', menuParent: 'company-header' }}
    list={ExistingCompanyOnboarding.list}
  />,
];
const HatchlyAdminResources = () => {
  return [
    <Resource
      name="hatchly-master-data"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={CompanyMaster.hatchlyList}
      show={CompanyMaster.show}
      edit={CompanyMaster.show}
    />,

    <Resource name="company" />,
    <CustomRoutes>
      <Route path="/company-pending-orders" element={<CompanyPendingOrders />} />
      <Route path="/company-archived-orders" element={<CompanyArchivedOrders />} />
    </CustomRoutes>,
  ];
};
const SuperAdminResources = () => {
  return [
    <Resource
      name="company-leads"
      options={{ label: 'Leads', menuParent: 'company-header' }}
      list={CompanyLeadsList.list}
    />,
    <Resource
      name="company-master-data"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={CompanyMaster.list}
      show={CompanyMaster.show}
      edit={CompanyMaster.show}
    />,
    <Resource
      name="hatchly-master-data"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={CompanyMaster.hatchlyList}
      show={CompanyMaster.show}
      edit={CompanyMaster.show}
    />,

    <Resource
      name="company-product-master"
      options={{ label: 'Products', menuParent: 'company-header' }}
      list={ListGuesser}
    />,
    <Resource
      name="franchise-change-logs"
      options={{ label: 'Franchise Change Logs', menuParent: 'company-header' }}
      list={FranchiseChangeLogResource.list}
      create={FranchiseChangeLogResource.create}
      show={FranchiseChangeLogResource.show}
    />,
    <Resource
      name="sg-employee-master"
      options={{ label: 'Employee', menuParent: 'company-header' }}
      list={EmployeeMasterDataResource.list}
      create={EmployeeMasterDataResource.create}
      show={EmployeeMasterDataResource.show}
      edit={EmployeeMasterDataResource.edit}
    />,
    <Resource
      name="sg-employee-compensation-master"
      options={{ label: 'Employee Compensation', menuParent: 'company-header' }}
      list={EmployeeCompensationMasterDataResource.list}
      create={EmployeeCompensationMasterDataResource.create}
      show={EmployeeCompensationMasterDataResource.show}
      edit={EmployeeCompensationMasterDataResource.edit}
    />,
    <Resource
      name="sg-ads-master"
      options={{ label: 'Ads', menuParent: 'company-header' }}
      list={AdsMaster.list}
      create={AdsMaster.create}
      show={AdsMaster.show}
      edit={AdsMaster.edit}
    />,
    ,
    <Resource
      name="sg-expense-master"
      options={{ label: 'Expense', menuParent: 'company-header' }}
      list={ExpenseMaster.list}
      create={ExpenseMaster.create}
      show={ExpenseMaster.show}
      edit={ExpenseMaster.edit}
    />,
    <Resource
      name="kpi-configuration-parameters"
      options={{ label: 'ConfigParameters', menuParent: 'company-header' }}
      list={KpiConfigParam.list}
      create={KpiConfigParam.create}
      show={KpiConfigParam.show}
      edit={KpiConfigParam.edit}
    />,
    <Resource
      name="kpi-instance-parameters"
      options={{ label: 'InstanceParameters', menuParent: 'company-header' }}
      list={KpiInstanceParam.list}
      create={KpiInstanceParam.create}
      show={KpiInstanceParam.show}
      edit={KpiInstanceParam.edit}
    />,
    <Resource
      name="registered-agent-payments"
      options={{ label: 'Reg. Agent Payments', menuParent: 'company-header' }}
      list={RegAgentPayments.list}
      edit={RegAgentPayments.edit}
      show={RegAgentPayments.show}
    />,
    <Resource
      name="company_reinstatement_master"
      options={{ label: 'Reinstatements', menuParent: 'company-header' }}
      list={CompanyReinstatements.list}
      edit={CompanyReinstatements.edit}
      show={CompanyReinstatements.show}
    />,
    <Resource
      name="registered_agent_transfer_master"
      options={{ label: 'RA Transfer', menuParent: 'company-header' }}
      list={RegisteredAgentTransfer.list}
      edit={RegisteredAgentTransfer.edit}
      show={RegisteredAgentTransfer.show}
    />,
    <Resource
      name="company_dissolution_master"
      options={{ label: 'Dissolutions', menuParent: 'company-header' }}
      list={CompanyDissolutions.list}
      edit={CompanyDissolutions.edit}
      show={CompanyDissolutions.show}
    />,
    <Resource
      name="franchise-master"
      options={{ label: 'Franchises', menuParent: 'administration-header' }}
      list={FranchiseMaster.list}
    />,
    <Resource
      name="company-archived-master"
      options={{ label: 'Archived Companies', menuParent: 'company-header' }}
      list={CompanyArchivedMasterResource.list}
      create={CompanyArchivedMasterResource.create}
      show={CompanyArchivedMasterResource.show}
      edit={CompanyArchivedMasterResource.edit}
    />,
    <Resource
      name="phone-number-registration-details"
      options={{ label: 'Phone Numbers', menuParent: 'company-header' }}
      list={PhoneNumberRegistrationDetailList.list}
      edit={PhoneNumberRegistrationDetailList.edit}
    />,
    <Resource
      name="franchise-tax"
      options={{ label: 'State Tax', menuParent: 'company-header' }}
      list={StateTax.list}
      show={StateTax.show}
    />,
    <Resource
      name="workflows/passport/pending"
      options={{ label: 'Passport', menuParent: 'company-header' }}
      list={PassportList.list}
    />,
    <Resource
      name="workflows/ss4/pending"
      options={{ label: 'SS4', menuParent: 'company-header' }}
      list={SS4List.list}
    />,
    <Resource
      name="workflows/state-filing-service"
      options={{ label: 'State Filing', menuParent: 'company-header' }}
      list={StateFilingServiceList.list}
    />,
    <Resource
      name="workflows/name-check-service/pending"
      options={{ label: 'Name Check', menuParent: 'company-header' }}
      list={StateFilingServiceList.nameCheckList}
    />,

    <Resource
      name="workflows/startglobal-state-filing-service"
      options={{ label: 'State Filings ( 48 states)', menuParent: 'company-header' }}
      list={StateFilings.list}
    />,
    <Resource
      name="workflows/startglobal-ein-service"
      options={{ label: 'EIN(Startglobal)', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/startglobal-fax-ein-service"
      options={{ label: 'EIN(Fax)', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/startglobal-fax-delayed-ein-service"
      options={{ label: 'EIN(Fax delayed)', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/ein-service-error-workflow"
      options={{ label: 'EIN( Error )', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/ein-service-usacorps"
      options={{ label: 'USACorps', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,

    <Resource
      name="workflows/ss4-service/pending-generation"
      options={{ label: 'SS4 (Not Generated)', menuParent: 'company-header' }}
      list={SS4PendingGenerationList.list}
      show={CompanyMaster.genericShow}
    />,

    <Resource
      name="email-registration-details"
      options={{ label: 'Email', menuParent: 'company-header' }}
      list={EmailRegistration.list}
      show={EmailRegistration.show}
      edit={EmailRegistration.edit}
    />,
    <Resource
      name="sg_view_subscription_master_data"
      options={{ label: 'Subscription', menuParent: 'company-header' }}
      list={SubscriptionMasterData.list}
      show={SubscriptionMasterData.show}
    />,
    <Resource
      name="mail-received-logs"
      options={{ label: 'Mails', menuParent: 'company-header' }}
      list={MailReceivedLogs.list}
      edit={MailReceivedLogs.edit}
    />,
    <Resource
      name="fax-logs"
      options={{ label: 'Faxs', menuParent: 'company-header' }}
      list={FaxLogs.list}
      show={FaxLogs.show}
    />,

    <Resource
      name="action-items"
      options={{ label: 'Action Items', menuParent: 'company-header' }}
      list={ActionItems.list}
      show={ActionItems.show}
      edit={ActionItems.edit}
    />,

    //Federal Tax Starts
    <Resource
      name="plaid-accounts"
      options={{ label: 'Income/Expense', menuParent: 'accounting-header' }}
      list={AccountingSummaryDetails.DataList}
    />,
    <Resource
      name="plaid-companies"
      options={{ label: 'Companies', menuParent: 'accounting-header' }}
      list={AccountingCompanySummaryDetails.DataList}
      // show={AccountingCompanySummaryDetails.DataShow}
      // edit={AccountingSummaryDetails.DataShow}
    />,
    <Resource
      name="federal-tax-summary/summary"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-chats"
      options={{ label: 'Chats', menuParent: 'company-header' }}
      list={FederalTaxChat.list}
    />,
    <Resource
      name="federal-tax-summary/revisions"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.revisionList}
    />,
    <Resource
      name="federal-tax-summary/penalties"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxPenalty.list}
    />,
    <Resource
      name="federal-tax-summary/calculate-income"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="be-12-surveys"
      options={{ label: 'BE12 Survey', menuParent: 'company-header' }}
      list={BE12Survey.list}
      show={BE12Survey.show}
    />,
    <Resource
      name="boi_master/all"
      options={{ label: 'Boi Filing', menuParent: 'company-header' }}
      list={BoiMaster.list}
      edit={BoiMaster.edit}
    />,
    <Resource
      name="company_addon_product_master"
      options={{ label: 'Addon Products', menuParent: 'company-header' }}
      list={AddonProductMaster.list}
      edit={AddonProductMaster.edit}
    />,
    <Resource
      name="boi_master/onhold"
      options={{ label: 'Boi Filing (Onhold)', menuParent: 'company-header' }}
      list={BoiMaster.list}
      edit={BoiMaster.edit}
    />,
    <Resource
      name="be-12-surveys/v2"
      options={{ label: 'BE12 Survey (2)', menuParent: 'company-header' }}
      list={BE12Survey.list}
      show={BE12Survey.show}
    />,

    //<Resource name="federal-tax-chats" options={{ "label": "Tax Chats", "menuParent": "accounting-header" }}  list={FederalTaxChatDetails.DataList} show={FederalTaxChatDetails.DataEdit}/>,
    //<Resource name="bookkeeping" options={{ "label": "Bookkeeping", "menuParent": "accounting-header" }}  list={BookkeepingDetails.DataList} show={BookkeepingDetails.DataEdit}/>,
    //Federal Tax ends
    <Resource
      name="company_bookkeeping_master"
      options={{ label: 'Bookkeeping', menuParent: 'company-header' }}
      list={CompanyBookkeeping.list}
    />,
    <Resource
      name="bookkeeping-view-chats"
      options={{ label: 'Chats', menuParent: 'company-header' }}
      list={CompanyBookkeepingChat.list}
    />,
    <Resource
      name="bookkeeping_summary"
      options={{ label: 'Bookkeeping', menuParent: 'company-header' }}
      list={CompanyBookkeeping.list}
    />,
    <Resource
      name="bookkeeping_pending_confirmation"
      options={{ label: 'Bookkeeping', menuParent: 'company-header' }}
      list={CompanyBookkeeping.list}
    />,
    <Resource
      name="sg_view_federal_tax_bookkeeping_workflow"
      options={{ label: 'Tax Workflow', menuParent: 'company-header' }}
      list={FederalTaxBookkeepingWorkflow.list}
    />,
    <Resource
      name="addons/FEDERAL_TAX_EXTENSION/business"
      options={{ label: 'Business Extensions', menuParent: 'company-header' }}
      list={FederalTaxBusinessExtensionAddon.list}
      edit={FederalTaxBusinessExtensionAddon.edit}
    />,
    <Resource
      name="addons/FEDERAL_TAX_EXTENSION/individual"
      options={{ label: 'Individual Extensions', menuParent: 'company-header' }}
      list={FederalTaxIndividualExtensionAddon.list}
      edit={FederalTaxIndividualExtensionAddon.edit}
    />,
    <Resource
      name="addons/addon_master"
      options={{ label: 'Addons Master', menuParent: 'company-header' }}
      list={AddonMaster.list}
      edit={AddonMaster.edit}
    />,

    <Resource
      name="mercury-bank-data/all"
      options={{ label: 'Mercury Accounts', menuParent: 'company-header' }}
      list={MercuryView.list}
    />,
    <Resource
      name="mercury-bank-data/approved"
      options={{ label: 'Approved Mercury Accounts', menuParent: 'company-header' }}
      list={MercuryView.approvedList}
    />,
    <Resource
      name="company_compliance_master/all"
      options={{ label: 'Entity Management', menuParent: 'company-header' }}
      list={EntityManagement.list}
    />,
    <Resource
      name="company_compliance_master/upcoming-payments"
      options={{ label: 'Upcoming Payments', menuParent: 'company-header' }}
      list={CompanyUpcomingPaymentsView.list}
    />,
    ...ApprovalsMenu,

    // <Resource
    //   name="lenco"
    //   options={{ label: 'Lenco Accounts', menuParent: 'company-header' }}
    //   show={LencoAccounts.show}
    //   list={LencoAccounts.list}
    //   edit={LencoAccounts.edit}
    // />,
    // <Resource
    //   name="ein_8822b_workflow"
    //   options={{ label: 'Ein8822b', menuParent: 'company-header' }}
    //   list={Ein8822b.list}
    //   show={Ein8822b.show}
    // />,
    <Resource name="company" />,
    <CustomRoutes>
      <Route path="/kpi-service" element={<KpiInstanceDetail />} />
      <Route path="/kpi-payments" element={<KpiPayments />} />

      <Route path="/company-pending-orders" element={<CompanyPendingOrders />} />
      <Route path="/company-archived-orders" element={<CompanyArchivedOrders />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/chats" element={<Chat />} />
      <Route path="/federal_tax_chat_new" element={<FederalTaxChats />} />
      <Route path="/local-batches" element={<LocalBatch.show />} />
      <Route path="/bank-statements" element={<BankStatements />} />
      <Route path="/monitoring/subscribers" element={<SubscriptionCompliance />} />
      <Route path="/whatsapp" element={<Whatsapp />} />
    </CustomRoutes>,
    <Resource
      name="tax-estimator-addon"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={TaxEstimatorAddonList.list}
      // show={CompanyMaster.show}
    />,
    <Resource
      name="operating-agreement-addon"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={OperatingAgreementAddonList.list}
    />,
    <Resource
      name="federal-tax-summary/cpa1/all"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-summary/cpa1/amendments"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.amendmentList}
    />,
    <Resource
      name="federal-tax-summary/cpa2"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-summary/cpa3"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-summary/all"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="call/twilio/all"
      options={{ label: 'Call Recordings', menuParent: 'company-header' }}
      list={TwilioCallRecording.list}
    />,
    <Resource
      name="email-blacklist-master"
      options={{ label: 'Email Black List', menuParent: 'company-header' }}
      list={EmailBlackListDetails.list}
      show={EmailBlackListDetails.show}
      edit={EmailBlackListDetails.edit}
      create={EmailBlackListDetails.create}
    />,
  ];
};

const GrowthHackerServices = () => {
  return [
    <Route path="/kpi-service" element={<KpiInstanceDetail />} />,
    <Route path="/kpi-payments" element={<KpiPayments />} />,
    <Route path="/reports" element={<Reports />} />,

    <Resource
      name="company-leads"
      options={{ label: 'Leads', menuParent: 'company-header' }}
      list={CompanyLeadsList.list}
    />,
    <Resource
      name="company-master-data"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={CompanyMaster.list}
      show={CompanyMaster.show}
      edit={CompanyMaster.show}
    />,
    <Resource
      name="company-product-master"
      options={{ label: 'Products', menuParent: 'company-header' }}
      list={ListGuesser}
    />,
    <Resource
      name="franchise-change-logs"
      options={{ label: 'Franchise Change Logs', menuParent: 'company-header' }}
      list={FranchiseChangeLogResource.list}
      create={FranchiseChangeLogResource.create}
      show={FranchiseChangeLogResource.show}
    />,
    <Resource
      name="sg-employee-master"
      options={{ label: 'Employee', menuParent: 'company-header' }}
      list={EmployeeMasterDataResource.list}
      create={EmployeeMasterDataResource.create}
      show={EmployeeMasterDataResource.show}
      edit={EmployeeMasterDataResource.edit}
    />,
    <Resource
      name="sg-employee-compensation-master"
      options={{ label: 'Employee Compensation', menuParent: 'company-header' }}
      list={EmployeeCompensationMasterDataResource.list}
      create={EmployeeCompensationMasterDataResource.create}
      show={EmployeeCompensationMasterDataResource.show}
      edit={EmployeeCompensationMasterDataResource.edit}
    />,
    <Resource
      name="sg-ads-master"
      options={{ label: 'Ads', menuParent: 'company-header' }}
      list={AdsMaster.list}
      create={AdsMaster.create}
      show={AdsMaster.show}
      edit={AdsMaster.edit}
    />,
    ,
    <Resource
      name="sg-expense-master"
      options={{ label: 'Expense', menuParent: 'company-header' }}
      list={ExpenseMaster.list}
      create={ExpenseMaster.create}
      show={ExpenseMaster.show}
      edit={ExpenseMaster.edit}
    />,
    <Resource
      name="kpi-configuration-parameters"
      options={{ label: 'ConfigParameters', menuParent: 'company-header' }}
      list={KpiConfigParam.list}
      create={KpiConfigParam.create}
      show={KpiConfigParam.show}
      edit={KpiConfigParam.edit}
    />,
    <Resource
      name="kpi-instance-parameters"
      options={{ label: 'InstanceParameters', menuParent: 'company-header' }}
      list={KpiInstanceParam.list}
      create={KpiInstanceParam.create}
      show={KpiInstanceParam.show}
      edit={KpiInstanceParam.edit}
    />,
    <Resource
      name="registered-agent-payments"
      options={{ label: 'Reg. Agent Payments', menuParent: 'company-header' }}
      list={RegAgentPayments.list}
      edit={RegAgentPayments.edit}
      show={RegAgentPayments.show}
    />,
    <Resource
      name="company_reinstatement_master"
      options={{ label: 'Reinstatements', menuParent: 'company-header' }}
      list={CompanyReinstatements.list}
      edit={CompanyReinstatements.edit}
      show={CompanyReinstatements.show}
    />,
    <Resource
      name="registered_agent_transfer_master"
      options={{ label: 'RA Transfer', menuParent: 'company-header' }}
      list={RegisteredAgentTransfer.list}
      edit={RegisteredAgentTransfer.edit}
      show={RegisteredAgentTransfer.show}
    />,
    <Resource
      name="company_dissolution_master"
      options={{ label: 'Dissolutions', menuParent: 'company-header' }}
      list={CompanyDissolutions.list}
      edit={CompanyDissolutions.edit}
      show={CompanyDissolutions.show}
    />,
    <Resource
      name="franchise-master"
      options={{ label: 'Franchises', menuParent: 'administration-header' }}
      list={FranchiseMaster.list}
    />,
    <Resource
      name="company-archived-master"
      options={{ label: 'Archived Companies', menuParent: 'company-header' }}
      list={CompanyArchivedMasterResource.list}
      create={CompanyArchivedMasterResource.create}
      show={CompanyArchivedMasterResource.show}
      edit={CompanyArchivedMasterResource.edit}
    />,
    <Resource
      name="phone-number-registration-details"
      options={{ label: 'Phone Numbers', menuParent: 'company-header' }}
      list={PhoneNumberRegistrationDetailList.list}
      edit={PhoneNumberRegistrationDetailList.edit}
    />,
    <Resource
      name="franchise-tax"
      options={{ label: 'State Tax', menuParent: 'company-header' }}
      list={StateTax.list}
      show={StateTax.show}
    />,
    <Resource
      name="workflows/passport/pending"
      options={{ label: 'Passport', menuParent: 'company-header' }}
      list={PassportList.list}
    />,
    <Resource
      name="workflows/ss4/pending"
      options={{ label: 'SS4', menuParent: 'company-header' }}
      list={SS4List.list}
    />,
    <Resource
      name="workflows/state-filing-service"
      options={{ label: 'State Filing', menuParent: 'company-header' }}
      list={StateFilingServiceList.list}
    />,
    <Resource
      name="workflows/name-check-service/pending"
      options={{ label: 'State Filing', menuParent: 'company-header' }}
      list={StateFilingServiceList.nameCheckList}
    />,
    <Resource
      name="workflows/startglobal-state-filing-service"
      options={{ label: 'State Filings ( 48 states)', menuParent: 'company-header' }}
      list={StateFilings.list}
    />,

    <Resource
      name="workflows/startglobal-ein-service"
      options={{ label: 'EIN(Startglobal)', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/startglobal-fax-ein-service"
      options={{ label: 'EIN(Fax)', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/startglobal-fax-delayed-ein-service"
      options={{ label: 'EIN(Fax delayed)', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/ein-service-error-workflow"
      options={{ label: 'EIN( Error )', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/ein-service-usacorps"
      options={{ label: 'USACorps', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,

    <Resource
      name="workflows/ss4-service/pending-generation"
      options={{ label: 'SS4 (Not Generated)', menuParent: 'company-header' }}
      list={SS4PendingGenerationList.list}
      show={CompanyMaster.genericShow}
    />,

    <Resource
      name="email-registration-details"
      options={{ label: 'Email', menuParent: 'company-header' }}
      list={EmailRegistration.list}
      show={EmailRegistration.show}
      edit={EmailRegistration.edit}
    />,
    <Resource
      name="sg_view_subscription_master_data"
      options={{ label: 'Subscription', menuParent: 'company-header' }}
      list={SubscriptionMasterData.list}
      show={SubscriptionMasterData.show}
    />,
    <Resource
      name="mail-received-logs"
      options={{ label: 'Mails', menuParent: 'company-header' }}
      list={MailReceivedLogs.list}
      edit={MailReceivedLogs.edit}
    />,
    <Resource
      name="fax-logs"
      options={{ label: 'Faxs', menuParent: 'company-header' }}
      list={FaxLogs.list}
      show={FaxLogs.show}
    />,

    <Resource
      name="action-items"
      options={{ label: 'Action Items', menuParent: 'company-header' }}
      list={ActionItems.list}
      show={ActionItems.show}
      edit={ActionItems.edit}
    />,

    //Federal Tax Starts
    //<Resource name="plaid-accounts" options={{ "label": "Income/Expense", "menuParent": "accounting-header" }}  list={AccountingSummaryDetails.DataList} />,
    //<Resource name="plaid-companies" options={{ "label": "Companies", "menuParent": "accounting-header" }}  list={AccountingCompanySummaryDetails.DataList}  show={AccountingCompanySummaryDetails.DataShow} edit={AccountingSummaryDetails.DataShow}/>,
    <Resource
      name="federal-tax-summary/summary"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-chats"
      options={{ label: 'Chats', menuParent: 'company-header' }}
      list={FederalTaxChat.list}
    />,
    <Resource
      name="federal-tax-summary/revisions"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.revisionList}
    />,
    <Resource
      name="federal-tax-summary/calculate-income"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,

    //<Resource name="federal-tax-chats" options={{ "label": "Tax Chats", "menuParent": "accounting-header" }}  list={FederalTaxChatDetails.DataList} show={FederalTaxChatDetails.DataEdit}/>,
    //<Resource name="bookkeeping" options={{ "label": "Bookkeeping", "menuParent": "accounting-header" }}  list={BookkeepingDetails.DataList} show={BookkeepingDetails.DataEdit}/>,
    //Federal Tax ends
    <Resource
      name="company_bookkeeping_master"
      options={{ label: 'Bookkeeping', menuParent: 'company-header' }}
      list={CompanyBookkeeping.list}
    />,
    <Resource
      name="bookkeeping-view-chats"
      options={{ label: 'Chats', menuParent: 'company-header' }}
      list={CompanyBookkeepingChat.list}
    />,
    <Resource
      name="bookkeeping_summary"
      options={{ label: 'Bookkeeping', menuParent: 'company-header' }}
      list={CompanyBookkeeping.list}
    />,
    <Resource
      name="bookkeeping_pending_confirmation"
      options={{ label: 'Bookkeeping', menuParent: 'company-header' }}
      list={CompanyBookkeeping.list}
    />,
    <Resource
      name="sg_view_federal_tax_bookkeeping_workflow"
      options={{ label: 'Tax Workflow', menuParent: 'company-header' }}
      list={FederalTaxBookkeepingWorkflow.list}
    />,
    <Resource
      name="addons/FEDERAL_TAX_EXTENSION/business"
      options={{ label: 'Business Extensions', menuParent: 'company-header' }}
      list={FederalTaxBusinessExtensionAddon.list}
      edit={FederalTaxBusinessExtensionAddon.edit}
    />,
    <Resource
      name="addons/FEDERAL_TAX_EXTENSION/individual"
      options={{ label: 'Individual Extensions', menuParent: 'company-header' }}
      list={FederalTaxIndividualExtensionAddon.list}
      edit={FederalTaxIndividualExtensionAddon.edit}
    />,
    <Resource
      name="addons/addon_master"
      options={{ label: 'Addons Master', menuParent: 'company-header' }}
      list={AddonMaster.list}
      edit={AddonMaster.edit}
    />,

    <Resource
      name="be-12-surveys"
      options={{ label: 'BE12 Survey', menuParent: 'company-header' }}
      list={BE12Survey.list}
      show={BE12Survey.show}
    />,
    <Resource
      name="be-12-surveys/v2"
      options={{ label: 'BE12 Survey (2)', menuParent: 'company-header' }}
      list={BE12Survey.list}
      show={BE12Survey.show}
    />,

    <Resource
      name="sg_view_subscription_master_data"
      options={{ label: 'Subscription', menuParent: 'company-header' }}
      list={SubscriptionMasterData.list}
      show={SubscriptionMasterData.show}
    />,
    <Resource
      name="company-master-data"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={CompanyMaster.list}
      show={CompanyMaster.show}
    />,
    <Resource
      name="franchise-master"
      options={{ label: 'Franchises', menuParent: 'administration-header' }}
      list={FranchiseMaster.list}
    />,
    <Resource
      name="federal-tax-summary/all"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="call/twilio/all"
      options={{ label: 'Call Recordings', menuParent: 'company-header' }}
      list={TwilioCallRecording.list}
    />,

    <Resource name="company" />,
    <CustomRoutes>
      <Route path="/reports" element={<Reports />} />
      <Route path="/kpi-service" element={<KpiInstanceDetail />} />
      <Route path="/kpi-payments" element={<KpiPayments />} />

      <Route path="/company-pending-orders" element={<CompanyPendingOrders />} />
      <Route path="/company-archived-orders" element={<CompanyArchivedOrders />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/chats" element={<Chat />} />
      <Route path="/federal_tax_chat_new" element={<FederalTaxChats />} />
      <Route path="/monitoring/subscribers" element={<SubscriptionCompliance />} />
      <Route path="/whatsapp" element={<Whatsapp />} />
    </CustomRoutes>,

    <Resource
      name="tax-estimator-addon"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={TaxEstimatorAddonList.list}
      // show={CompanyMaster.show}
    />,
    <Resource
      name="operating-agreement-addon"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={OperatingAgreementAddonList.list}
    />,
    <Resource
      name="boi_master/all"
      options={{ label: 'Boi Filing', menuParent: 'company-header' }}
      list={BoiMaster.list}
      edit={BoiMaster.edit}
    />,

    <Resource
      name="boi_master/onhold"
      options={{ label: 'Boi Filing (Onhold)', menuParent: 'company-header' }}
      list={BoiMaster.list}
      edit={BoiMaster.edit}
    />,
  ];
};
const USACorpsResources = () => {
  return [
    <Resource
      name="workflows/startglobal-fax-delayed-ein-service"
      options={{ label: 'EIN(Fax delayed)', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/ein-service-error-workflow"
      options={{ label: 'EIN( Error )', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/ein-service-usacorps"
      options={{ label: 'USACorps', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,

    <Resource name="company" />,
  ];
};
const StateFilerResources = () => {
  return [
    <CustomRoutes>
      <Route path="/chats" element={<Chat />} />
      <Route path="/federal_tax_chat_new" element={<FederalTaxChats />} />
    </CustomRoutes>,
    <Resource
      name="be-12-surveys/v2"
      options={{ label: 'BE12 Survey', menuParent: 'company-header' }}
      list={BE12Survey.list}
      show={BE12Survey.show}
    />,
    <Resource
      name="company-master-data"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={CompanyMaster.list}
      show={CompanyMaster.show}
      edit={CompanyMaster.show}
    />,
    <Resource
      name="boi_master/all"
      options={{ label: 'Boi Filing', menuParent: 'company-header' }}
      list={BoiMaster.list}
      edit={BoiMaster.edit}
    />,
    <Resource
      name="company_addon_product_master"
      options={{ label: 'Addon Products', menuParent: 'company-header' }}
      list={AddonProductMaster.list}
      edit={AddonProductMaster.edit}
    />,
    <Resource name="company" />,
  ];
};
const BookkeepingResources = () => {
  return [
    <Resource
      name="bookkeeping_summary"
      options={{ label: 'Bookkeeping', menuParent: 'company-header' }}
      list={CompanyBookkeeping.list}
    />,
    <Resource
      name="bookkeeping-view-chats"
      options={{ label: 'Chats', menuParent: 'company-header' }}
      list={CompanyBookkeepingChat.list}
    />,
    <Resource
      name="company-master-data"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={CompanyMaster.list}
      show={CompanyMaster.show}
      edit={CompanyMaster.show}
    />,
    <Resource name="company" />,
    <CustomRoutes>
      <Route path="/chats" element={<Chat />} />
    </CustomRoutes>,
  ];
};

const CPA0988Resources = () => {
  return [
    <Resource
      name="federal-tax-summary/summary"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-chats"
      options={{ label: 'Chats', menuParent: 'company-header' }}
      list={FederalTaxChat.list}
    />,
    <Resource name="company" />,
    <Resource
      name="company-master-data"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={CompanyMaster.list}
      show={CompanyMaster.show}
      edit={CompanyMaster.show}
    />,
    <Resource
      name="be-12-surveys"
      options={{ label: 'BE12 Survey', menuParent: 'company-header' }}
      list={BE12Survey.list}
      show={BE12Survey.show}
    />,
    <Resource
      name="company_dissolution_master"
      options={{ label: 'Dissolutions', menuParent: 'company-header' }}
      list={CompanyDissolutions.list}
      edit={CompanyDissolutions.edit}
      show={CompanyDissolutions.show}
    />,
    <CustomRoutes>
      <Route path="/chats" element={<Chat />} />
    </CustomRoutes>,
  ];
};

const CPA0128Resources = () => {
  return [
    <Resource
      name="federal-tax-summary/cpa1/all"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,

    <Resource
      name="federal-tax-summary/summary"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-summary/revisions"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.revisionList}
    />,
    <Resource
      name="federal-tax-summary/calculate-income"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,

    <Resource
      name="federal-tax-chats"
      options={{ label: 'Chats', menuParent: 'company-header' }}
      list={FederalTaxChat.list}
    />,
    <Resource name="company" />,
    <Resource
      name="company-master-data"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={CompanyMaster.list}
      show={CompanyMaster.show}
      edit={CompanyMaster.show}
    />,
    <Resource
      name="be-12-surveys"
      options={{ label: 'BE12 Survey', menuParent: 'company-header' }}
      list={BE12Survey.list}
      show={BE12Survey.show}
    />,
    <Resource
      name="company_dissolution_master"
      options={{ label: 'Dissolutions', menuParent: 'company-header' }}
      list={CompanyDissolutions.list}
      edit={CompanyDissolutions.edit}
      show={CompanyDissolutions.show}
    />,
    <CustomRoutes>
      <Route path="/chats" element={<Chat />} />
    </CustomRoutes>,
  ];
};
const CPA1Resources = () => {
  return [
    <Resource
      name="federal-tax-summary/cpa1/all"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-summary/cpa1/amendments"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.amendmentList}
    />,
    <Resource
      name="federal-tax-summary/revisions"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.revisionList}
    />,

    <Resource name="company" />,
    <Resource
      name="company-master-data"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={CompanyMaster.list}
      show={CompanyMaster.show}
      edit={CompanyMaster.show}
    />,
    <Resource
      name="company_dissolution_master"
      options={{ label: 'Dissolutions', menuParent: 'company-header' }}
      list={CompanyDissolutions.list}
      edit={CompanyDissolutions.edit}
      show={CompanyDissolutions.show}
    />,
    <CustomRoutes>
      <Route path="/chats" element={<Chat />} />
    </CustomRoutes>,
  ];
};
const CPA3Resources = () => {
  return [
    <Resource
      name="federal-tax-summary/cpa3"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-summary/cpa2"
      options={{ label: 'Zero Transactions Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-summary/revisions"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.revisionList}
    />,
    <Resource
      name="federal-tax-summary/penalties"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxPenalty.list}
    />,
    <Resource name="company" />,
    <Resource
      name="company-master-data"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={CompanyMaster.list}
      show={CompanyMaster.show}
      edit={CompanyMaster.show}
    />,
    <Resource
      name="company_dissolution_master"
      options={{ label: 'Dissolutions', menuParent: 'company-header' }}
      list={CompanyDissolutions.list}
      edit={CompanyDissolutions.edit}
      show={CompanyDissolutions.show}
    />,

    <CustomRoutes>
      <Route path="/chats" element={<Chat />} />
    </CustomRoutes>,
  ];
};

const StartGlobalSupportTeamResources = () => {
  return [
    <Resource
      name="federal-tax-summary/cpa1/all"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-summary/cpa1/amendments"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.amendmentList}
    />,
    <Resource
      name="federal-tax-summary/cpa3"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-summary/penalties"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxPenalty.list}
    />,
    <Resource
      name="federal-tax-summary/cpa2"
      options={{ label: 'Zero Transactions Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-summary/revisions"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.revisionList}
    />,

    <Resource
      name="company-leads"
      options={{ label: 'Leads', menuParent: 'company-header' }}
      list={CompanyLeadsList.list}
    />,
    <Resource
      name="company-master-data"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={CompanyMaster.list}
      show={CompanyMaster.show}
      edit={CompanyMaster.show}
    />,
    <Resource
      name="registered-agent-payments"
      options={{ label: 'Reg. Agent Payments', menuParent: 'company-header' }}
      list={RegAgentPayments.list}
      edit={RegAgentPayments.edit}
      show={RegAgentPayments.show}
    />,
    <Resource
      name="company_reinstatement_master"
      options={{ label: 'Reinstatements', menuParent: 'company-header' }}
      list={CompanyReinstatements.list}
      edit={CompanyReinstatements.edit}
      show={CompanyReinstatements.show}
    />,
    <Resource
      name="registered_agent_transfer_master"
      options={{ label: 'RA Transfer', menuParent: 'company-header' }}
      list={RegisteredAgentTransfer.list}
      edit={RegisteredAgentTransfer.edit}
      show={RegisteredAgentTransfer.show}
    />,
    <Resource
      name="company_dissolution_master"
      options={{ label: 'Dissolutions', menuParent: 'company-header' }}
      list={CompanyDissolutions.list}
      edit={CompanyDissolutions.edit}
      show={CompanyDissolutions.show}
    />,
    <Resource
      name="company-archived-master"
      options={{ label: 'Archived Companies', menuParent: 'company-header' }}
      list={CompanyArchivedMasterResource.list}
      create={CompanyArchivedMasterResource.create}
      show={CompanyArchivedMasterResource.show}
      edit={CompanyArchivedMasterResource.edit}
    />,
    <Resource
      name="franchise-tax"
      options={{ label: 'State Tax', menuParent: 'company-header' }}
      list={StateTax.list}
      show={StateTax.show}
    />,
    <Resource
      name="workflows/passport/pending"
      options={{ label: 'Passport', menuParent: 'company-header' }}
      list={PassportList.list}
    />,
    <Resource
      name="workflows/ss4/pending"
      options={{ label: 'SS4', menuParent: 'company-header' }}
      list={SS4List.list}
    />,
    <Resource
      name="workflows/state-filing-service"
      options={{ label: 'State Filing', menuParent: 'company-header' }}
      list={StateFilingServiceList.list}
    />,
    <Resource
      name="workflows/name-check-service/pending"
      options={{ label: 'State Filing', menuParent: 'company-header' }}
      list={StateFilingServiceList.nameCheckList}
    />,
    <Resource
      name="workflows/startglobal-state-filing-service"
      options={{ label: 'State Filings ( 48 states)', menuParent: 'company-header' }}
      list={StateFilings.list}
    />,
    <Resource
      name="workflows/startglobal-ein-service"
      options={{ label: 'EIN(Startglobal)', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/startglobal-fax-ein-service"
      options={{ label: 'EIN(Fax)', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/startglobal-fax-delayed-ein-service"
      options={{ label: 'EIN(Fax delayed)', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/ein-service-error-workflow"
      options={{ label: 'EIN( Error )', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,
    <Resource
      name="workflows/ein-service-usacorps"
      options={{ label: 'USACorps', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,

    <Resource
      name="workflows/ss4-service/pending-generation"
      options={{ label: 'SS4 (Not Generated)', menuParent: 'company-header' }}
      list={SS4PendingGenerationList.list}
      show={CompanyMaster.genericShow}
    />,

    <Resource
      name="mail-received-logs"
      options={{ label: 'Mails', menuParent: 'company-header' }}
      list={MailReceivedLogs.list}
      edit={MailReceivedLogs.edit}
    />,
    <Resource
      name="fax-logs"
      options={{ label: 'Faxs', menuParent: 'company-header' }}
      list={FaxLogs.list}
      show={FaxLogs.show}
    />,

    <Resource
      name="action-items"
      options={{ label: 'Action Items', menuParent: 'company-header' }}
      list={ActionItems.list}
      show={ActionItems.show}
      edit={ActionItems.edit}
    />,

    //Federal Tax Starts
    //<Resource name="plaid-accounts" options={{ "label": "Income/Expense", "menuParent": "accounting-header" }}  list={AccountingSummaryDetails.DataList} />,
    //<Resource name="plaid-companies" options={{ "label": "Companies", "menuParent": "accounting-header" }}  list={AccountingCompanySummaryDetails.DataList}  show={AccountingCompanySummaryDetails.DataShow} edit={AccountingSummaryDetails.DataShow}/>,
    <Resource
      name="federal-tax-summary/summary"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-chats"
      options={{ label: 'Chats', menuParent: 'company-header' }}
      list={FederalTaxChat.list}
    />,
    <Resource
      name="federal-tax-summary/revisions"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.revisionList}
    />,
    <Resource
      name="federal-tax-summary/calculate-income"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,

    <Resource
      name="be-12-surveys"
      options={{ label: 'BE12 Survey', menuParent: 'company-header' }}
      list={BE12Survey.list}
      show={BE12Survey.show}
    />,
    <Resource
      name="be-12-surveys/v2"
      options={{ label: 'BE12 Survey (2)', menuParent: 'company-header' }}
      list={BE12Survey.list}
      show={BE12Survey.show}
    />,

    //<Resource name="federal-tax-chats" options={{ "label": "Tax Chats", "menuParent": "accounting-header" }}  list={FederalTaxChatDetails.DataList} show={FederalTaxChatDetails.DataEdit}/>,
    //<Resource name="bookkeeping" options={{ "label": "Bookkeeping", "menuParent": "accounting-header" }}  list={BookkeepingDetails.DataList} show={BookkeepingDetails.DataEdit}/>,
    //Federal Tax ends
    <Resource
      name="company_bookkeeping_master"
      options={{ label: 'Bookkeeping', menuParent: 'company-header' }}
      list={CompanyBookkeeping.list}
    />,
    <Resource
      name="bookkeeping-view-chats"
      options={{ label: 'Chats', menuParent: 'company-header' }}
      list={CompanyBookkeepingChat.list}
    />,
    <Resource
      name="bookkeeping_summary"
      options={{ label: 'Bookkeeping', menuParent: 'company-header' }}
      list={CompanyBookkeeping.list}
    />,
    <Resource
      name="bookkeeping_pending_confirmation"
      options={{ label: 'Bookkeeping', menuParent: 'company-header' }}
      list={CompanyBookkeeping.list}
    />,
    <Resource
      name="sg_view_federal_tax_bookkeeping_workflow"
      options={{ label: 'Tax Workflow', menuParent: 'company-header' }}
      list={FederalTaxBookkeepingWorkflow.list}
    />,
    <Resource
      name="mercury-bank-data/all"
      options={{ label: 'Mercury Accounts', menuParent: 'company-header' }}
      list={MercuryView.list}
    />,
    <Resource
      name="mercury-bank-data/approved"
      options={{ label: 'Approved Mercury Accounts', menuParent: 'company-header' }}
      list={MercuryView.approvedList}
    />,
    <Resource
      name="company_compliance_master/all"
      options={{ label: 'Entity Management', menuParent: 'company-header' }}
      list={EntityManagement.list}
    />,
    <Resource
      name="company_compliance_master/upcoming-payments"
      options={{ label: 'Upcoming Payments', menuParent: 'company-header' }}
      list={CompanyUpcomingPaymentsView.list}
    />,
    <Resource
      name="federal-tax-summary/all"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    ...ApprovalsMenu,
    <Resource
      name="email-blacklist-master"
      options={{ label: 'Email Black List', menuParent: 'company-header' }}
      list={EmailBlackListDetails.list}
      show={EmailBlackListDetails.show}
      edit={EmailBlackListDetails.edit}
      create={EmailBlackListDetails.create}
    />,
    <Resource
      name="boi_master/all"
      options={{ label: 'Boi Filing', menuParent: 'company-header' }}
      list={BoiMaster.list}
      edit={BoiMaster.edit}
    />,

    <Resource
      name="boi_master/onhold"
      options={{ label: 'Boi Filing (Onhold)', menuParent: 'company-header' }}
      list={BoiMaster.list}
      edit={BoiMaster.edit}
    />,
    <Resource
      name="company_addon_product_master"
      options={{ label: 'Addon Products', menuParent: 'company-header' }}
      list={AddonProductMaster.list}
      edit={AddonProductMaster.edit}
    />,
    <Resource name="company" />,
    <CustomRoutes>
      <Route path="/company-pending-orders" element={<CompanyPendingOrders />} />
      <Route path="/company-archived-orders" element={<CompanyArchivedOrders />} />
      <Route path="/chats" element={<Chat />} />
      <Route path="/bank-statements" element={<BankStatements />} />
      <Route path="/monitoring/subscribers" element={<SubscriptionCompliance />} />
    </CustomRoutes>,
  ];
};

const SDRResources = () => {
  return [
    <Resource
      name="federal-tax-summary/summary"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-chats"
      options={{ label: 'Chats', menuParent: 'company-header' }}
      list={FederalTaxChat.list}
    />,
    <Resource
      name="federal-tax-summary/revisions"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.revisionList}
    />,
    <Resource
      name="bookkeeping_summary"
      options={{ label: 'Bookkeeping', menuParent: 'company-header' }}
      list={CompanyBookkeeping.list}
    />,
    <Resource
      name="bookkeeping_pending_confirmation"
      options={{ label: 'Bookkeeping', menuParent: 'company-header' }}
      list={CompanyBookkeeping.list}
    />,
    <Resource
      name="company_bookkeeping_master"
      options={{ label: 'Bookkeeping', menuParent: 'company-header' }}
      list={CompanyBookkeeping.list}
    />,
    <Resource
      name="workflows/startglobal-fax-delayed-ein-service"
      options={{ label: 'EIN(Fax delayed)', menuParent: 'company-header' }}
      list={EinService.list}
      show={EinService.show}
    />,

    <Resource
      name="company_compliance_master/all"
      options={{ label: 'Entity Management', menuParent: 'company-header' }}
      list={EntityManagement.list}
    />,
    <Resource
      name="company_compliance_master/upcoming-payments"
      options={{ label: 'Upcoming Payments', menuParent: 'company-header' }}
      list={CompanyUpcomingPaymentsView.list}
    />,
    <Resource
      name="company-master-data"
      options={{ label: 'Companies', menuParent: 'company-header' }}
      list={CompanyMaster.list}
      show={CompanyMaster.show}
      edit={CompanyMaster.show}
    />,
    // <Resource
    //   name="company-pending-payment-master"
    //   options={{ label: 'Pending Companies', menuParent: 'company-header' }}
    //   list={CompanyMaster.list}
    //   show={CompanyMaster.show}
    //   edit={CompanyMaster.show}
    // />,
    <Resource
      name="federal-tax-summary/all"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource name="company" />,
    <Resource
      name="email-blacklist-master"
      options={{ label: 'Email Black List', menuParent: 'company-header' }}
      list={EmailBlackListDetails.list}
      show={EmailBlackListDetails.show}
      edit={EmailBlackListDetails.edit}
      create={EmailBlackListDetails.create}
    />,
    <Resource
      name="federal-tax-summary/cpa1/all"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,

    <Resource
      name="federal-tax-summary/cpa3"
      options={{ label: 'Federal Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,
    <Resource
      name="federal-tax-summary/cpa2"
      options={{ label: 'Zero Transactions Tax', menuParent: 'company-header' }}
      list={FederalTaxSummary.list}
    />,

    <CustomRoutes>
      <Route path="/chats" element={<Chat />} />
    </CustomRoutes>,
  ];
};
const App = () => {
  const hasPermission = (permissions, _permission) => {
    return permissions && Array.isArray(permissions) && permissions.indexOf(_permission) >= 0;
  };
  return (
    <Admin
      disableTelemetry
      authProvider={authProvider}
      dataProvider={dataProvider}
      // customRoutes={customRoutes}
      loginPage={Login}
      title="StartGlobal Platform Admin"
      requireAuth
      layout={Layout}
    >
      {(permissions) => (
        <>
          {hasPermission(permissions, 'ROLE_GROWTH_HACKER') ? GrowthHackerServices() : null}
          {hasPermission(permissions, 'ROLE_SUPER_ADMIN') ? SuperAdminResources() : null}
          {hasPermission(permissions, 'ROLE_EIN_SERVICE_USA_CORPS') ? USACorpsResources() : null}
          {hasPermission(permissions, 'ROLE_BOOK_KEEPER') ? BookkeepingResources() : null}
          {hasPermission(permissions, 'ROLE_CPA_0988') ? CPA0988Resources() : null}
          {hasPermission(permissions, 'ROLE_CPA_0128') ? CPA0128Resources() : null}
          {hasPermission(permissions, 'ROLE_CPA_1') ? CPA1Resources() : null}
          {hasPermission(permissions, 'ROLE_CPA_3') ? CPA3Resources() : null}
          {hasPermission(permissions, 'ROLE_HATCHLY_ADMIN') ? HatchlyAdminResources() : null}

          {hasPermission(permissions, 'ROLE_STATE_FILER') ? StateFilerResources() : null}
          {hasPermission(permissions, 'ROLE_EIN_SERVICE_STARTGLOBAL')
            ? StartGlobalSupportTeamResources()
            : null}
          {hasPermission(permissions, 'ROLE_SDR') ? SDRResources() : null}
        </>
      )}
    </Admin>
  );
};
export default App;
