import * as React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { useDataProvider, useNotify } from 'react-admin';

import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const ComplianceList = (props) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>RA</TableCell>
            <TableCell>Franchise</TableCell>
            <TableCell>Federal</TableCell>
            <TableCell>Final</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={props.id}>
            <TableCell>1</TableCell>

            <TableCell>{props.registeredAgentStatus}</TableCell>
            <TableCell>{props.franchiseTaxStatus}</TableCell>
            <TableCell> {props.federalTaxStatus}</TableCell>
            <TableCell>{props.status}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const FranchiseTaxList = (props) => {
  const rows = props.data;
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();

  if (!rows || rows.length <= 0) {
    return <div>No data found</div>;
  }

  const handleClickOpen = (row) => {
    setOpen(true);
    setSelectedValue(row);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(null);
  };

  const onClickOfRow = (row) => handleClickOpen(row);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Year</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Payment Status</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={props.id}
              onClick={() => onClickOfRow(row)}
              style={{
                cursor: 'pointer',
              }}
            >
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row.year}</TableCell>
              <TableCell>{row.dueDate}</TableCell>
              <TableCell> {row.amount}</TableCell>
              <TableCell>{row.paymentStatus}</TableCell>
              <TableCell>{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <StatusDialog
        open={open}
        data={{ ...selectedValue, service: 'Franchise Tax' }}
        onClose={handleClose}
      />
    </TableContainer>
  );
};

const RegisteredAgentDetailsList = (props) => {
  const rows = props.data;
  if (!rows || rows.length <= 0) {
    return <div>No data found</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Year</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Payment Status</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={props.id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row.year}</TableCell>
              <TableCell>{row.startDate}</TableCell>
              <TableCell>{row.endDate}</TableCell>
              <TableCell>{row.dueDate}</TableCell>
              <TableCell> {row.amount}</TableCell>
              <TableCell>{row.paymentStatus}</TableCell>
              <TableCell>{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CompliaceMaster = (props) => {
  console.log('compliance master', props.data);
  const [loading, setIsloading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [reload, setReload] = React.useState(false);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    setIsloading(true);
    const url = `company_compliance_master/details/company/${props.data.id}`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('compliance', data);
        //data comes in the ascending order.
        //But we like to keep the most recent order at the top
        setData(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);

  if (loading) {
    return null;
  }
  if (data.length <= 0) {
    return (
      <>
        <div>No data found</div>
      </>
    );
  }

  const getColor = (status) => {
    if (status === 'ACTIVE') {
      return 'success';
    }
    if (status === 'AT_RISK') {
      return 'warning';
    }
    return 'error';
  };
  return (
    <Box
      sx={{
        width: `clamp(600px, calc(100vw - 250px), 100%)`,
      }}
    >
      <Grid container spacing={1}>
        <Typography variant="h6" gutterBottom>
          Compliance
          <Chip
            label={data?.companyComplianceMaster?.status}
            color={getColor(data?.companyComplianceMaster?.status)}
            style={{
              margin: '4px',
            }}
          />
        </Typography>
        <ComplianceList {...data.companyComplianceMaster} />
      </Grid>
      <Spacer />
      <Grid>
        <Typography variant="h6">
          Registered Agent Compliance
          <Chip
            label={data?.companyComplianceMaster?.registeredAgentStatus}
            color={getColor(data?.companyComplianceMaster?.registeredAgentStatus)}
            style={{
              margin: '4px',
            }}
          />
        </Typography>

        <RegisteredAgentDetailsList data={data.registeredAgentFeeDetails} />
      </Grid>
      <Spacer />
      <Grid>
        <Typography variant="h6">
          Franchise Tax Compliance
          <Chip
            label={data?.companyComplianceMaster?.franchiseTaxStatus}
            color={getColor(data?.companyComplianceMaster?.franchiseTaxStatus)}
            style={{
              margin: '4px',
            }}
          />
        </Typography>

        <FranchiseTaxList data={data.franchiseTaxDetails} />
      </Grid>
      <Spacer />
    </Box>
  );
};
const Spacer = () => <Box pt={6}>&nbsp;</Box>;

const StatusDialog = (props) => {
  const { onClose, selectedValue, open } = props;

  const [saving, setSaving] = React.useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };
  const [status, setStatus] = React.useState('');

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const saveStatus = () => {
    //call the api.
    // if success, show success message
    // then close the dialog, refresh the parent component
    //if error, then show the error message
    if (status === '') {
      return;
    }

    setSaving(true);
    const url = `company_compliance_master/company/${props.data.companyId}/franchise-tax/${props.data.id}/status`;
    const data = {
      companyId: props.data.companyId,
      status: status,
      id: props.data.id,
    };
    dataProvider
      .post(url, { data })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'success') {
          notify('Data updated successfully', 'success');
          onClose();
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setSaving(false));
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Change Status</DialogTitle>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1 },
          width: '100%',
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={1}>
          <Grid item>
            <TextField label="Service" defaultValue={props.data?.service} disabled />
          </Grid>
          <Grid item>
            <TextField required label="Year" defaultValue={props.data?.year} disabled />
          </Grid>
          <Grid item>
            <TextField required label="Due Date" defaultValue={props.data?.dueDate} disabled />
          </Grid>
          <Grid item>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={status || props.data?.status}
                label="Status"
                onChange={handleChange}
                style={{
                  minWidth: '200px',
                }}
              >
                {[
                  ...new Set([
                    'PAID',
                    'NOT_PAID',
                    'TAX_PAID_EXTERNALLY',
                    'CONFIRMED',
                    props.data?.status ? props.data.status : null,
                  ]),
                ].map((status) => (
                  <MenuItem value={status}>{status}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            style={{
              margin: 'auto',
            }}
          >
            <LoadingButton variant="contained" loading={saving} onClick={saveStatus}>
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default CompliaceMaster;
