import EmployeeIcon from '@mui/icons-material/People';

import FederalTaxRevisionList from './FederalTaxRevisionList';
import EinServiceList from './FederalTaxSummaryList';
import EinServiceView from './FederalTaxSummaryView';
import FedTaxAmendmentList from './FedTaxAmendmentList';

const resource = {
  list: EinServiceList,
  revisionList: FederalTaxRevisionList,
  show: EinServiceView,
  icon: EmployeeIcon,
  amendmentList: FedTaxAmendmentList,
};

export default resource;
